@import '../../sass/main.scss';

div.main-container.RegistrationFormContainer {
  @include respond-to(mobile) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.RegistrationFormContainer {
  margin-top: -20%;
  height: max-content;

  @include respond-to(tablet) {
    padding: 2rem 6rem;
    border-bottom: 0 0 125px 125px;
  }
  @include respond-to(mobile) {
    padding: 20px 0px;
    .main-container {
      padding: 0 !important;
    }
  }
  @include respond-to(desktop) {
    padding: 2rem 12rem;
  }

  .contanierStyle.ui.container {
    @include respond-to(mobile) {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  .styleSegment.ui.segment {
    border-radius: 1.5rem;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1.6rem;
    margin-bottom: 3.1rem;
    box-shadow: 0px 0.25rem 0.5rem $box-shadow-color;
  }
  .gridStyling {
    .gridRow {
      padding-bottom: 0px;
      margin-top: 2rem;
    }
    .gridTitle {
      margin-bottom: 1.6rem;
    }
    .textColumn {
      @include respond-to(desktop) {
        padding: 0px 8.6rem;
      }
      @include respond-to(tablet) {
        padding-right: 8rem;
        margin-bottom: 3rem;
      }
      @include respond-to(mobile) {
        margin-bottom: 3rem;
      }
      text-align: left;
    }
    .IndicateRequiredText {
      display: flex;
      gap: 0.4rem;
      margin: 1.4rem 0 3rem;
      span {
        color: $dark-orange;
      }
    }
    .gridColumnStyle {
      .notDesktopStyle {
        @include respond-to(desktop) {
          padding: 1.25rem 0px 0px 50px;
        }
        @include respond-to(mobile) {
          padding: 10px 20px;
        }
        @include respond-to(tablet) {
          padding: 1.25rem 0px 0px 3.12rem;
        }
      }
      .Form {
        @include respond-to(tablet) {
          padding: 1.87rem 3.75rem 1.87rem 0px;
        }
        @include respond-to(mobile) {
          padding: 1.87rem 0px 0px 0px;
        }
        .eachField {
          margin-bottom: 1.8rem;
          margin-right: 2.5rem;
        }
      }
    }
    .gridColumnStyle.column {
      padding-left: 0px;
    }
    .imageDesign {
      position: relative;
    }
    .Image {
      @include respond-to(desktop) {
        bottom: -5vh;
      }
      @include respond-to(tablet) {
        bottom: -6vh;
      }
      @include respond-to(mobile) {
        bottom: -7vh;
      }
    }
    .gridColumn2 {
      .Form {
        @include respond-to(desktop) {
          padding: 1.87rem;
        }
        @include respond-to(tablet) {
          padding: 1.87rem 1.87rem 1.87rem 0px;
        }
        @include respond-to(mobile) {
          padding-right: 8rem;
        }
        .eachField {
          margin-bottom: 1.56rem;
        }
      }
    }
  }
}
