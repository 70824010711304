@import '../../../sass/main.scss';

.InputField,
.TextAreaField {
  display: flex;
  align-items: center;
  .error-border {
    border: 1px solid $darkRed !important;
  }
}
.FieldLabel {
  display: flex;
  font-family: $font-family-bold;
  font-size: $caption-font-size;
  letter-spacing: 0px;
  color: $BlackLightShade;

  &.disabled {
    color: var(--color-dark-grey);
  }
}
.HTMLLabelWrapper {
  display: flex;
  align-items: center;
}
.HtmlLabelAsterisk {
  color: $gradient-start-color;
  font-size: $p-font-size;
  margin-left: 4px;
}
.AsteriskStyle {
  color: $gradient-start-color;
  margin-left: 4px;
  height: 22px;
}
.PrimaryInput,
.PrimaryTextArea {
  padding-bottom: 10px;
  position: relative;
  padding-top: 5px;
  .ui.input > input,
  .ui.form textarea {
    border: 1px solid var(--color-dark-grey);
    border-radius: 0px;
    font-family: $font-family;
    font-size: $p-font-size;
    color: $BlackLightShade;
  }

  textarea {
    border: 1px solid var(--color-dark-grey) !important;
    font-family: $font-family;
    font-size: $p-font-size !important;

    &:focus {
      border: 1px solid $dark-focus-blue !important;
      border-radius: 0px !important;
    }
  }

  // focus state
  .ui.input > input:focus {
    border: 1px solid $dark-focus-blue;
    border-radius: 0px;
  }
  // error state
  .ui.input.error > input,
  .ui.input.error > textarea {
    border: 1px solid $darkRed;
    box-shadow: none;
    background-color: $white;
    color: $BlackLightShade;
  }
  .ErrorMsg {
    font-family: $font-family;
    font-size: $caption-mobile-font-size;
    letter-spacing: 0px;
    color: $darkRed;
  }
  .InfoIcon {
    padding-left: 8px;
  }
  .ui.image {
    max-width: initial;
  }
  // .ui.icon.input>i.icon {
  //   top: 25%
  // }
  .passwordIcon {
    right: 4%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.PrimaryInput {
  .ErrorMsgContainer .ErrorMsg {
    margin-top: 0.4rem;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}