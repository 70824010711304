@import '../../../sass/main.scss';

.MobileWebNavbar {
  width: 100%;
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
  box-shadow: 0px 4px 8px $box-shadow-color;
  z-index: 2;

  .segments {
    margin: 0 !important;
    border: 0 !important;
    align-items: center;
    &::before,
    &::after {
      border: none !important;
      transform: none !important;
    }
    .SecondaryMobileWebNavbar {
      border: none;
      padding: 0;
      background-color: $brand-orange-secondary;
    }
    .PrimaryMobileWebNavbar {
      border: none;
      padding: 0;
      border-top: 4px solid $brand-orange-secondary;

      .HamburgerButton {
        background-color: transparent;
        color: $brand-orange;
      }
      .HamburgerIcon {
        padding: 0;
        padding-right: 2.4rem;
        svg {
          width: 2.4rem;
          height: 1.7rem;
        }
      }
      .ui.horizontal.segments > .segment:first-child {
        padding: 0.9rem 0 0.9rem 2.4rem;
      }
    }
  }
}

.SideNavbar {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  .SubMenuHeader {
    margin: 2rem 1.4rem;
    .BackButton,
    .BackButton:focus {
      background-color: transparent;
      padding: 1rem;
      background-color: transparent;
      margin: 0;

      svg {
        width: 2rem;
        height: 2rem;

        path:last-child {
          fill: $brand-orange;
        }
      }
    }

    .ui.header {
      font-family: var(--font-family-bold) !important;
      font-size: var(--h4-font-size) !important;
      color: $rich-black;
      padding: 0 1rem;
    }
  }
  .MobileWebNavbarHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 2rem 0 2.4rem !important;
    width: auto;
    .segments {
      width: 100%;
      align-items: center;
      border: none !important;
      box-shadow: none !important;
    }
    .CloseButton,
    .CloseButton:focus {
      padding: 15px 0;
      background-color: transparent;
      color: $brand-orange;
      margin: 0;

      svg {
        width: 3.6rem;
        height: 3.6rem;
      }
    }
    .AuthPagesButton {
      padding: 0;
      a {
        font-size: var(--p-font-size);
        background-color: transparent;
        color: $brand-blue;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }
      }
      span {
        font-size: 2.2rem;
        color: $dim-gray;
        margin: 0 5px;
      }
    }
    .SearchIcon {
      color: $brand-blue;
    }
  }
  .MobileWebNavbarItem {
    font-size: var(--h6-font-size) !important;
    padding: 2rem 2.4rem !important;
    display: flex;
    justify-content: space-between;
    color: $black;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid $light-gray !important;

    svg {
      width: 2.4rem;
      height: 2.4rem;

      path:last-child {
        fill: $rich-black;
      }
    }

    &::before {
      height: 0 !important;
    }
  }
}
