@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.center-testimonials-container {
  padding: 25px 0 80px 0;
  .center-testimonials-slider.slick-slider {
    svg {
      &:hover {
        fill: $brand-orange;
      }
    }
    margin: auto;
    @include respond-to(tablet) {
      width: 90%;
    }
    @include respond-to(mobile) {
      width: 90%;
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .testimonial-card-container.card {
      box-shadow: 0px 4px 8px #33333340 !important;
      min-height: 315px !important;
      border-radius: 25px !important;
      max-width: 270px !important;
      margin: 25px 10px;
      @include respond-to(desktop) {
        &:hover {
          cursor: pointer;
          transition: ease-out 0.2s;
          transform: scale(1.1);
          z-index: 999;
        }
      }
      @include respond-to(tablet) {
        width: 90%;
      }
      @include respond-to(mobile) {
        width: 90%;
      }
      .header {
        font-size: var(--h5-font-size) !important;
        padding: 10px 0 0 24px;
      }
      .content {
        padding: 0 24px 24px 24px;
        @include respond-to(mobile) {
          padding: 15px;
        }
        border: none;
        .description {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 0 !important;
        }
        .patient-verbiage {
          font-size: var(--caption-font-size);
          margin: 10px 0;
          letter-spacing: 0.5px;
          word-spacing: 0.5px;
          padding: 1em 0 0;
        }
        .patient-details div {
          .patient-title {
            color: $brand-orange;
          }
          padding-bottom: 0;
          > div {
            &:first-child {
              padding-left: 0;
              padding-right: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
          h5 {
            color: $brand-orange;
          }
          color: black;
        }
      }
    }
    .slick-list {
      margin: 0 70px;
      @include respond-to(mobile) {
        margin: 0;
      }
    }
    .slick-dots {
      bottom: -55px;
      li {
        width: 8px;
      }
      li button:before {
        background-color: black !important;
        opacity: 0.5;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: black !important;
        border-radius: 5px;
        opacity: 1;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      left: 10px;
      z-index: 99;
    }
    .slick-next {
      right: 10px;
      z-index: 99;
    }
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
  }
}

.center-meet-our-staff-container.LessDataWrapper {
  .slick-slider {
    .slick-track {
      margin-left: 0;
    }
    .slick-slide.slick-cloned {
      display: none;
      @include respond-to(mobile) {
        display: block;
      }
    }
  }
}
