@import '../../../sass/main.scss';

.WhatToDoContanier2 {
  @include respond-to(tablet) {
    padding: 0 var(--padding-horizontal-tablet);
  }

  @include respond-to(mobile) {
    padding: 0 var(--padding-horizontal-mobile);
  }
  .manageGridColumnPadding {
    @include respond-to(mobile) {
      margin-bottom: 5rem !important;
    }
    @include respond-to(tablet) {
      margin-bottom: 5rem !important;
      width: 100% !important;
      padding-left: 0 !important;
    }
    &:first-of-type {
      @include respond-to(desktop) {
        padding-left: 0 !important;
      }
    }
  }
  @include respond-to(mobile) {
    .ui.grid > .column.row > [class*='eight wide computer'].column,
    .ui.grid > .row > [class*='eight wide computer'].column {
      width: 100% !important;
    }
  }
  .mainText {
    letter-spacing: -0.48px;
    * {
      font: normal normal bold 48px/54px Avenir Next;
    }
  }
  .headingText {
    text-align: center;
    margin-bottom: 1rem;
  }

  .paragraph {
    text-align: center;
    margin-bottom: 4rem;

    @include respond-to(tablet) {
      min-height: 70px;
    }

    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }

  .IconImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .IconImage {
      width: 12rem;
      height: 12rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .button--cta {
    display: block;
    margin: auto;
  }
}
