@import '../../sass/main.scss';

.StatisticContainer {
  background-color: inherit;
  @include respond-to(tablet) {
  }
  @include respond-to(mobile) {
    padding-left: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ui.stackable.grid {
    max-width: 1366px;
    margin: 0 auto !important;

    @include respond-to(tablet) {
      padding-right: 0 !important;
    }

    @include respond-to(mobile) {
      padding: 0 !important;
    }

    .StatisticsRow.row {
      padding-top: 0;
      justify-content: space-between;
      @include respond-to(mobile) {
        flex-direction: column-reverse;
      }
      .TextBlock.column {
        margin-top: 96px;
        padding-left: var(--padding-horizontal-desktop);
        @include respond-to(tablet) {
          margin-top: 64px;
          padding-left: 0;
          padding-right: 2.4rem;
        }
        @include respond-to(mobile) {
          width: 100% !important;
          padding-left: 6%;
          padding-right: 6%;
        }
        .TextContent {
          margin-bottom: 0;
          padding-left: 0;
          @include respond-to(tablet) {
            width: 100%;
            padding-left: 0;
          }
          @include respond-to(mobile) {
            padding-left: 2em;
            width: 100%;
            padding-right: 2em;
          }
          .StatisticsTitle {
            margin-top: 0;
            margin-bottom: 0;
            color: inherit;
          }
          .StatisticsDescription {
            margin-top: 24px;
            @include respond-to(mobile) {
              margin-top: 24px;
            }
          }
        }
        .CurrentStatisticsContainer {
          padding-left: 0;
          @include respond-to(mobile) {
            padding-left: 2em;
            padding-right: 2em;
          }
          @include respond-to(tablet) {
            padding-left: 0;
          }
          .CurrentStatsBox {
            margin-top: 8px;
            padding: 32px;
            border-radius: 25px;
            background-color: $white;
            box-shadow: 0px 4px 8px $box-shadow-color;
            width: 80%;
            @include respond-to(tablet) {
              width: 100%;
            }
            @include respond-to(mobile) {
              width: 100%;
              padding: 28px;
              margin-top: 0;
            }
            .CurrentStatsTitle {
              margin-bottom: 1.6rem;
              font-family: $font-family-bold !important;
              font-size: $p-font-size;
            }
            .CurrentStatsValue {
              margin-top: 0;
              font-size: var(--h3-font-size);
              line-height: normal;
              font-family: $font-family-bold !important;
            }
            .CurrentStatsDescription {
              font-size: $p-font-size;
            }
          }
        }
      }
      .ImageBlock.wide.column {
        width: 46% !important;
        padding-right: 0;
        padding-left: 0;
        @include respond-to(tablet) {
          width: 40% !important;
          padding-left: 0;
        }
        @include respond-to(mobile) {
          width: 100% !important;
          padding-top: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        img {
          width: 100%;
          border-bottom-left-radius: 5rem;
          border-bottom-right-radius: 0;
        }
      }
    }
    .StatisticsRow.row.Reversed {
      flex-direction: row-reverse;
      @include respond-to(tablet) {
        flex-direction: row-reverse;
      }
      .ImageBlock.column img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 5rem;
      }
      @include respond-to(mobile) {
        flex-direction: column-reverse;
      }
    }
  }
}
.StatisticContainer.ContainerReversed {
  display: flex;
  justify-content: flex-end;
  padding-left: 0;

  @include respond-to(tablet) {
    padding-right: 3.5em;
    padding-left: 0;

    .ui.stackable.grid {
      padding-left: 0 !important;
    }
  }
  @include respond-to(mobile) {
    padding: 0;
  }
  .Reversed {
    .TextBlock.column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: var(--padding-horizontal-desktop);
      padding-left: 2.4rem !important;

      .TextContent {
        padding-right: 0;
        @include respond-to(mobile) {
          padding-right: 2em;
        }
        .StatisticsTitle {
          text-align: right;
        }
        .StatisticsDescription {
          text-align: right;
        }
      }
      .CurrentStatisticsContainer {
        display: flex;
        justify-content: flex-end;
        padding-right: 0;
        width: 100%;
        @include respond-to(mobile) {
          padding-right: 2em;
        }
        .CurrentStatsBox {
          text-align: right;
          width: 100%;
        }
      }
    }
    .ImageBlock.column {
      padding-right: 1em;
    }
  }
}
