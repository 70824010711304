@import '../../sass/main.scss';
.blogs--container {
  .ui.input > input {
    border-radius: 0px 8px 8px 0px !important;
    border-color: $medium-gray;
  }

  .ui.icon.input > i.icon {
    border-radius: 0px 8px 8px 0px !important;
  }
  .ui.search .prompt {
    border-radius: 0px 8px 8px 0px !important;
  }

  .ui.icon.input > i.link.icon {
    opacity: 1;
    background-color: $brand-blue;
    color: $white;
    cursor: pointer;
  }
  // .ui.icon.input > input {
  //   padding: 0.5rem 1rem;
  // }
  .ui.input > input {
    height: 5.5rem;
    font-family: 'AvenirNextRegular' !important;
    font-size: var(--p-font-size);
  }

  // Blog Container

  /* The container */
  .OptionContainer {
    color: #484848;
    font-size: var(--caption-font-size);
    font-family: 'AvenirNextRegular' !important;
    letter-spacing: 0.32px;
    position: relative;
    padding-left: 4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .OptionContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 3.2rem;
    width: 3.2rem;
    background-color: white;
    border: 1px solid $medium-gray;
  }

  /* When the checkbox is checked, add a blue background */
  .OptionContainer input:checked ~ .checkmark {
    background-color: $rich-black;
    //   border-radius: 2px;
  }

  /* Create the checkmark/indicator (hidden when not checked)*/
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .OptionContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .OptionContainer .checkmark:after {
    left: 11px;
    top: 4px;
    width: 9px;
    height: 20px;
    border: solid white;
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .NoSearchResults {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 8px;
    p {
      margin-bottom: 0px;
      margin-left: 8px;
      color: #484848;
      font-size: var(--p-font-size);
      font-weight: 700;
      line-height: var(--p-line-height);
    }
    img {
      width: 24px;
    }
  }
  .NoResultsFound {
    padding: 0 171px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
    line-height: 0;
    p:last-child {
      font-family: 'AvenirNextRegular' !important;
      color: $rich-black;
    }
    @include respond-to(mobile) {
      padding: 0;
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }
}
