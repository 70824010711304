@import '../../sass/main.scss';

.ShareWithUsSuccessPageContainer {
  .paddedRegion {
    padding-bottom: 0px;
    @include respond-to(mobile) {
      padding-bottom: 32px;
    }
  }
  .main-container {
    .text-component-title {
      padding-top: 80px;
      width: 70%;
      @include respond-to(mobile) {
        width: 90%;
        padding-top: 32px;
      }
    }

    .text-component-description {
      padding-top: 16px !important;
      padding-bottom: 80px !important;
      @include respond-to(mobile) {
        padding-bottom: 48px !important;
      }
    }
  }
  .separator-wrapper {
    .Separator {
      @include respond-to(mobile) {
        width: 98% !important;
      }
    }
  }
}
