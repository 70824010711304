@import '../../../sass/main.scss';

.SelectSearch {
  position: relative;
  padding-bottom: 10px;
  display: inline-flex;
  height: 20px;
  margin-left: 10px;
  .Required {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 6px;
    color: $dark-pink;
  }
  .ui.dropdown.Base {
    // width: 198px;
    background-color: transparent;
    color: $green;
    font-size: 2.3rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    i.icon {
      line-height: 3.8rem;
      color: $green;
      font-size: $caption-font-size;
      margin-left: 15px;
    }
  }
  .Filter {
    input {
      text-overflow: ellipsis;
    }
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $white;
    font-family: $font-family;
    font-size: 20.8px;
    font-weight: bold;
    letter-spacing: 0;
  }
  .ui.dropdown .menu {
    max-height: max-content;
  }
  .ui.dropdown.Base.Error {
    border: 1px solid $dark-pink;
  }
  .ui.dropdown > .menu {
    width: 300px;
    box-shadow: none;
    top: 24px;
  }
  .ui.active.dropdown .menu {
    border: $gray;
  }
  .ui.dropdown .menu > .item {
    font-size: $p-font-size;
    font-family: $font-family;
  }
  .ui.active.dropdown {
    border-bottom-left-radius: 32.5px !important;
    border-bottom-right-radius: 32.5px !important;
    border-top-left-radius: 32.5px !important;
    border-top-right-radius: 32.5px !important;
  }
  .ui.dropdown .menu > .item {
    padding: 20px 15px !important;
  }
  .ui.visible.dropdown > .text:not(.default) {
    font-weight: bold;
    color: $white;
  }
  .ui.upward.dropdown:hover {
    box-shadow: none;
  }
  .ui.active.dropdown:hover {
    box-shadow: none;
  }
  .ui.active.dropdown {
    box-shadow: none;
  }
  .ui.dropdown .menu > .divider {
    margin: 0;
  }
  .ui.dropdown .menu > .input:not(.transparent) input {
    border-radius: 0px;
    font-size: $p-font-size;
    font-family: $font-family;
    padding: 15px 14px;
  }

  @media only screen and (max-width: 520px) {
    // Making the Center dropdown center aligned on smaller screen
    width: 93%;
    .ui.dropdown > .menu {
      left: 50%;
      transform: translate(-50%, 0);
      position: relative;
      top: 0;
    }
    .Filter {
      padding-top: 10px;
    }
    // Making font-size smaller for smaller screen
    .ui.dropdown .menu > .header {
      font-size: 0.7em;
      margin-top: 0.75rem;
      margin-bottom: 0.5rem;
    }
    .ui.dropdown .scrolling.menu > .item.item.item {
      padding: 0.5em 1em !important;
      width: 330px;
      max-width: 100%;
      overflow: hidden;
      word-wrap: normal;
      white-space: normal;
    }
    .ui.dropdown .menu > .item .text {
      font-size: 0.75em !important;
    }
  }
}
