@import '../../sass/main.scss';

.FooterContainer {
  color: $white;
  .Footer.segment {
    margin-top: 0px;
    // padding: 48px 12.8em;    //keeping this for future reference
    padding: 0;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    background-color: inherit;
    @include respond-to(mobile) {
      padding: 0;
    }
    @include respond-to(tablet) {
      // padding: 0 3.5em;    //keeping this for future reference
      padding: 0;
    }
    .FooterTopRow {
      @include respond-to(tablet) {
        padding-bottom: 0;
      }
      .StoreLogoContainer {
        display: flex;
        justify-content: flex-start;
        @include respond-to(tablet) {
          padding-right: 0;
        }
        .segment {
          @include respond-to(tablet) {
            display: flex;
            justify-content: flex-end;
            padding-right: 0;
          }
          @include respond-to(mobile) {
            padding-top: 0;
            padding-bottom: 0;
          }
          .StoreLogoWrapper {
            .StoreLogos {
              display: flex;
              justify-content: flex-start;
              column-gap: 1vw;
              .FooterLogoContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 0;
                .ui.medium.image {
                  width: 100%;
                  @include respond-to(tablet) {
                    width: 250px;
                  }
                  @include respond-to(mobile) {
                    width: 290px;
                  }
                  img {
                    @include respond-to(tablet) {
                      width: 100%;
                    }
                    @include respond-to(mobile) {
                      width: 100%;
                    }
                  }
                }
                &:first-child {
                  @include respond-to(tablet) {
                    margin-bottom: 32px;
                  }
                }
                &:last-child {
                  @include respond-to(mobile) {
                    justify-content: flex-end;
                  }
                }
                @include respond-to(mobile) {
                  width: 48%;
                }
              }
              @include respond-to(tablet) {
                flex-direction: column;
              }
              @include respond-to(mobile) {
                column-gap: 0px;
                justify-content: space-between;
              }
            }
            @include respond-to(tablet) {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      .BiolifeLogoBox {
        padding-left: 0;
        .BiolifeLogoContainer {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 0;
          @include respond-to(mobile) {
            padding-left: 14px;
          }
          .ui.medium.image {
            width: 200px;
          }
        }
      }
    }
    .Reversed {
      flex-direction: row-reverse;
      .SubscribeBoxContainer {
        padding-left: 0;
        @include respond-to(mobile) {
          padding-left: 1em;
        }
      }
      .BiolifeLogoContainer {
        justify-content: flex-end;
        padding-right: 0;
        @include respond-to(mobile) {
          padding-right: 1em;
        }
        @include respond-to(tablet) {
          justify-content: flex-end;
        }
      }
      @include respond-to(tablet) {
        flex-direction: row-reverse;
        .StoreLogoContainer .segment {
          justify-content: flex-start;
          padding-left: 0;
        }
      }
    }
    .FooterMiddleRow {
      padding-top: 2rem;
      @include respond-to(tablet) {
        // padding-left: 14px;
        padding-bottom: 8px;
      }
      .column {
        padding-left: 0;
        padding-right: 0;
      }
      .FooterMenu.menu {
        border: 0;
        @include respond-to(mobile) {
          padding-left: 1em !important;
          padding-right: 1em !important;
        }
        @include respond-to(tablet) {
          justify-content: flex-start;
        }
        .FooterMiddleRowMenuGroup {
          .item {
            @include respond-to(tablet) {
              width: auto;
              font-size: var(--caption-font-size);
              text-align: left;
            }
          }
          @include respond-to(mobile) {
            align-self: flex-start;
          }
          @include respond-to(tablet) {
            &:last-child {
              align-self: flex-start;
            }
          }
        }
        .menu {
          width: 100%;
          justify-content: flex-start;
          font-size: 1.4rem;
          flex-wrap: wrap;

          .item {
            color: $white;
            padding-left: 0;
            padding-right: 0;
            margin-right: 2.3rem;

            &:last-child {
              margin-right: 0;
            }
            @include respond-to(mobile) {
              width: auto;
              text-align: left;
            }
            &:hover,
            &:focus,
            &:active {
              text-decoration: underline;
              background-color: transparent !important;
            }
          }
          .item.active {
            border-color: transparent;
            @include respond-to(mobile) {
              background-color: transparent;
            }
            @include respond-to(tablet) {
              background-color: transparent;
            }
          }
        }
      }
    }
    .DownloadAppText {
      font-family: Avenir Next;
      font-size: var(--caption-font-size);
      letter-spacing: -0.16px;
      color: inherit;

      p {
        margin-bottom: 14px;
      }
    }
    .SubscribeBoxContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-right: 0;
      @include respond-to(mobile) {
        padding-right: 1em;
      }
      .LatestNewsTitle {
        font-family: Avenir Next;
        font-size: 1.6rem;
        letter-spacing: 0px;
        color: inherit;
      }
      .InputBtn {
        color: $medium-gray;
        font-size: 1.8rem;
        font-family: 'Avenir Next';
        line-height: 26px;
        .InputBtnArrow {
          box-shadow: none;
          padding: 0;
          &:hover,
          &:focus,
          &:active {
            border: none;
            background-color: transparent !important;
            box-shadow: none;
          }
          div {
            &:hover {
              svg > path {
                fill: #c75105;
              }
              svg g path {
                fill: #fff;
              }
            }
          }
        }
      }
      @include respond-to(tablet) {
        margin-top: 10px;
        padding-left: 0;
      }
    }
    .FooterBottomRow {
      padding-top: 0;
      margin-bottom: 80px;
      justify-content: space-between;
      @include respond-to(mobile) {
        padding-left: 0;
        margin-bottom: 60px !important;
      }
      @include respond-to(tablet) {
        flex-wrap: nowrap;
        margin-bottom: 66px;
      }
      .TrademarkStatement.column {
        padding-left: 0;
        @include respond-to(mobile) {
          padding-top: 0 !important;
        }
        .TrademarkStatementText {
          margin-top: 30px;
          color: inherit;
          @include respond-to(mobile) {
            padding-left: 1em !important;
            padding-right: 1em !important;
          }
        }
      }
      .TrademarkLogo {
        height: 100%;
        align-content: center;
        .TakedaFooterLogo {
          padding-top: 10%;
        }
      }
      .SocialIcons.column {
        height: 100%;
        width: 26% !important;
        padding-top: 16px;
        padding-right: 0;
        align-content: center;
        text-align: left;
        @include respond-to(mobile) {
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 0 !important;
          width: 100% !important;
          flex-wrap: wrap;
        }
        @include respond-to(tablet) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-end;
          width: unset !important;
          padding-left: 0;
        }
        .IconButton {
          background-color: transparent;
          &:last-child {
            padding-right: 0;
          }
          &:hover svg path {
            fill: $social-icon-hover-color;
          }
          &:active svg path {
            fill: $white;
          }
          @include respond-to(mobile) {
            &:last-child {
              padding-right: 1em;
            }
          }
        }
      }
    }
  }
  .Footer.segment.ui.basic {
    .ui.fluid.container {
      @include respond-to(mobile) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .MobileFooterContainer.ui.stackable {
        margin-left: -14px !important;
        margin-right: -14px !important;
      }
    }
  }
}
