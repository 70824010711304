@import '../../sass/main.scss/';

.NoCenterFoundContainer {
  .main-container {
    .text-component-title {
      @include respond-to(tablet) {
        padding-right: 15rem;
      }

      @include respond-to(desktop) {
        padding-right: 20rem;
      }
    }
  }

  @include respond-to(desktop) {
    margin-bottom: 5.5rem;
  }

  margin-bottom: 4rem;
}
