@import '../../sass/main.scss';

.SignUpContainer {
  .main-container {
    .text-component-title {
      width: 70%;
    }
    .text-component-description {
      padding-top: 24px;
      padding-bottom: 0px;
      margin: 0px;
      width: 70%;
    }
    .AccordionContainer,
    .SignUpForm {
      width: 70%;
      .ui.accordion .content {
        width: 79%;
      }
      .ui.error.message {
        border: 1px solid $darkRed;
        background-color: $validation-pink-background;
        color: $darkRed;
        box-shadow: none;
      }
    }
    .Separator {
      margin: 0 !important;
      width: 70%;
    }

    @include respond-to(tablet) {
      .text-component-title,
      .text-component-description {
        width: 100%;
      }

      .AccordionContainer,
      .SignUpForm {
        width: 70%;
      }
    }

    @include respond-to(mobile) {
      .text-component-title,
      .text-component-description,
      .AccordionContainer,
      .SignUpForm {
        width: 100%;
        .ui.accordion .content {
          width: 100%;
        }
      }
      .Separator {
        width: auto !important;
      }
    }
  }
}
