@import '../../../sass/main.scss';

.grid-template2 {
  &__points-container {
    .slick-slider {
      width: 90vw;
      padding-bottom: 5px;
      .slick-dots li {
        width: 8px;
      }
      .slick-dots li button:before {
        font-size: 0px;
        width: 8px;
        height: 8px;
        background-color: black;
        border-radius: 100%;
        top: 25%;
      }
      .slick-dots li.slick-active {
        width: 16px;
      }
      .slick-dots li.slick-active button:before {
        font-size: 0px;
        width: 16px;
        height: 8px;
        background-color: black;
        border-radius: 10px;
        top: 25%;
      }
    }
  }
  &__point-title {
    @include respond-to(mobile) {
      width: 60%;
    }
  }
}
