@import '../../sass/main.scss';
.ImageBanner {
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
}
.BannerTemplate {
  .ImageBannerGrid {
    align-items: center;
    margin: 0 auto;
    .ImageBannerRow {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .subTitle {
      padding-bottom: 30px;
      padding-top: 16px;
    }
  }
  .reversed {
    display: flex !important;
    flex-direction: row-reverse !important;
    .title {
      padding-top: 20px;
    }
  }
  @include respond-to(tablet) {
    .reversed {
      flex-direction: column-reverse !important;
    }
  }
  @include respond-to(mobile) {
    .title {
      font: normal normal bold 29px/38px Avenir Next !important;
    }
    .ImageBannerRow {
      text-align: center;
    }
    .ImageBannerButton {
      width: 100%;
    }
    .reversed {
      flex-direction: column-reverse !important;
    }
    .ImageBannerGrid {
      .subTitle {
        padding-top: 8px !important;
      }
    }
  }
}
