@import '../../sass/main.scss';

.CentersCarouselContainer {
  width: 100%;
  .text-component__container {
    padding-bottom: 32px;
    .text-component-title {
      :first-child {
        margin-bottom: 8px;
      }
    }
  }
  .loader-bar-component {
    margin-top: 20px;
    margin-bottom: 20px;
    @include respond-to(mobile) {
      margin-top: 10px;
    }
  }
  .CentersCarousel {
    .slick-slider {
      margin: 0 -10px 50px;
      .slick-list {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 8px;
        @include respond-to(mobile) {
          padding: 10px calc((100vw - 420rem) / 2) 10px !important;
        }
      }
      .slick-dots {
        bottom: -50px;
        li {
          width: 8px;
        }
        li button:before {
          background-color: $medium-gray;
          opacity: 1;
          border-radius: 10px;
          height: 8px;
          width: 8px;
          text-indent: -9999px;
          overflow: hidden;
        }
        li.slick-active {
          width: 16px;
        }
        li.slick-active button:before {
          background-color: $text-gray;
          border-radius: 5px;
          height: 8px;
          width: 16px;
          margin-left: 0;
          margin-right: 0;
        }
      }
      .slick-prev {
        width: 48px;
        height: 48px;
        left: -60px;
        &:hover {
          path {
            fill: $brand-orange;
          }
        }
      }
      .slick-next {
        width: 48px;
        height: 48px;
        right: -42px;
        &:hover {
          path {
            fill: $brand-orange;
          }
        }
      }
    }
  }
  .CentersCarousel.LessDataWrapper {
    .slick-slider {
      margin-bottom: 0;
      .slick-track {
        margin-left: 0;
      }
      .slick-list {
        .slick-track {
          @include respond-to(desktop) {
            margin-left: 0;
            margin-right: 0;
          }
          .slick-slide.slick-cloned {
            display: none;
            @include respond-to(tablet) {
              display: block;
            }
            @include respond-to(mobile) {
              display: block;
            }
          }
        }
      }
    }
  }
}
