@import '../../sass/main.scss';

.ShareWithUsMainContainer {
  .paddedRegion {
    padding-bottom: 0px;
    @include respond-to(mobile) {
      padding-bottom: 32px;
    }
  }

  .text-component__container {
    .text-component-title {
      padding-top: 80px;
      width: 70% !important;
      h2 strong {
        font-size: var(--h3-font-size);
        @include respond-to(mobile) {
          font-size: var(--h3-mobile-font-size);
        }
      }
      @include respond-to(mobile) {
        width: 90% !important;
        padding-top: 32px;
      }
    }

    .text-component-description {
      width: 70% !important;
      padding: 16px 0 30px 0 !important;
      @include respond-to(mobile) {
        width: 90% !important;
        padding: 16px 0 24px 0 !important;
      }
    }
  }
  .separator-wrapper {
    .Separator {
      @include respond-to(mobile) {
        width: 98% !important;
      }
    }
  }
  .SecondTestimonialsSliderContainer.ui.grid {
    margin-bottom: 0rem !important;
    @include respond-to(mobile) {
      margin-bottom: 2.4rem !important;
    }
  }
}
