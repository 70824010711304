@import '../../../sass/main.scss';
.ui.form .message.negative, 
.ui.form .warning.message {
  background-color: #c700001a;
  color: #c70000;
  border-radius: 0px !important;
  box-shadow: none;
  border: 1px solid;
  padding: 8px;

  .header {
    font-family: $font-family-bold !important;
    font-size: $p-font-size;
    color: $darkRed;
    padding: 0.5rem 0 0;
  }
}

.ui.icon.message > .icon:not(.close) {
  color: var(--color-white);
}
.ui.icon.message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px;
  background-color: $darkRed;
  color: var(--color-white);
  padding: 8px;
  border-radius: 0px;
  z-index: 1;

  .content > p {
    color: var(--color-white) !important;
    padding-left: 0.8rem;
    font-size: var(--caption-mobile-font-size);
    line-height: 2rem;

    a {
      font-family: var(--font-family-bold) !important;
      color: var(--color-white) !important;
      text-decoration: underline;
    }
  }
}
.SearchInputButton {
  padding-bottom: 10px;
  position: relative;
  padding-top: 5px;
  .ui.input > input {
    border: 1px solid #25358e;
    border-radius: 0px;
    font-family: $font-family;
    font-size: $p-font-size;
    color: $BlackLightShade;
  }
  .FieldLabel {
    font-family: $font-family-bold;
    font-size: $caption-font-size;
    letter-spacing: 0px;
    color: $BlackLightShade;
    display: flex;
  }
  // focus state
  .ui.input > input:focus {
    border: 1px solid $dark-focus-blue;
    border-radius: 0px;
  }
  // error state
  .ui.input.error > input {
    border: 0px solid $white;
    box-shadow: none;
    background-color: var(--color-validation-red-input);
  }
  // disabled state
  .ui.input > input:disabled {
    background-color: #c700001a;
    opacity: 1;
  }

  .ErrorMsg {
    font-family: $font-family;
    font-size: $caption-mobile-font-size;
    letter-spacing: 0px;
    color: $darkRed;
  }
  .InfoIcon {
    padding-left: 8px;
  }
  .error.input {
    min-width: -webkit-fill-available;
  }
  .ui.button.SolidBlue.SolidBlueButton {
    width: 56px;
    height: 56px;
    border-radius: 0 25px 25px 0 !important;
    padding-left: 10px !important;
  }
  .ui.image {
    max-width: initial;
  }
}
