@import '../../sass/main.scss';

.HeroTemplate5Container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 50px;
  @include respond-to(mobile) {
    padding: 15px 50px;
  }
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  & > .ui.grid {
    @include respond-to(desktop) {
      max-width: 1008px;
    }
  }
  .HeroGridColumn {
    display: flex;
    .HeroContentContainer {
      text-align: left;
      .HeroTitleContainer {
        width: 80%;
        @include respond-to(mobile) {
          width: 100%;
        }
        & > h2 {
          font-size: var(--h2-font-size) !important;
          line-height: 5.4rem !important;

          @include respond-to(tablet) {
            font-size: var(--h3-font-size) !important;
            line-height: 4.6rem !important;
          }

          @include respond-to(mobile) {
            font-size: var(--h4-font-size) !important;
            line-height: 3.8rem !important;
          }
        }
      }
      .HeroDescriptionContainer {
        padding-top: 25px;
        & > p {
          font-size: var(--h6-font-size);
          line-height: var(--h6-line-height);
          width: 90%;

          @include respond-to(mobile-tablet) {
            font-size: var(--p-font-size);
          }
        }
      }
      .ButtonContainer {
        padding-top: 32px;
        display: flex;
        gap: 32px;
      }
    }
    .HeroImgContainer {
      .ImgHeading {
        text-align: center;
        font-size: $h5-font-size !important;
        font-family: AvenirNextBold !important;
        color: $brand-orange;
        margin-bottom: 24px;
        @include respond-to(mobile) {
          text-align: left;
          font-size: $h3-mobile-font-size !important;
          margin-bottom: 16px;
        }
      }
      a {
        overflow: hidden;
        display: block;
        border-radius: 24px;
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0 auto;

        @include respond-to(mobile-tablet) {
          width: 100%;
        }

        .backdrop {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.25);
          z-index: 1;
          border-radius: 24px;
          transition: all 0.5s ease;

          @include respond-to(mobile) {
            width: 100%;
          }

          &:hover,
          &:focus,
          &:active {
            background: transparent;

            & + img {
              transform: scale(1.2);
            }
          }
        }

        img.ui.image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          aspect-ratio: auto;
          transition: all 0.3s ease;

          &:hover,
          &:focus,
          &:active {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  .ui.grid > .row > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.HeroTemplate5Container.ReverseStylesContainer {
  .HeroGridColumn.ReverseStyles {
    flex-direction: row-reverse;
  }
}

@include respond-to(tablet) {
  .Hero {
    padding: 30px 10px 30px 60px;
    background-position: bottom;
    .HeroGridColumn {
      .HeroContentContainer {
        .HeroTitleContainer {
          width: 94%;
          & > h2 {
            font-size: var(--h1-mobile-font-size) !important;
            line-height: var(--h3-line-height);
          }
        }
        .HeroDescriptionContainer {
          & > p {
            font-size: var(--h5-mobile-font-size);
            line-height: var(--h5-mobile-line-height);
            width: 100%;
          }
        }
      }
    }
  }
}

@include respond-to(mobile) {
  .Hero {
    padding: 45px 24px 0px 24px;
    background-position-y: -10vw, -47vw;
    .HeroGridColumn {
      .HeroContentContainer {
        .HeroTitleContainer {
          width: 100%;
          & > h2 {
            font-size: var(--h1-mobile-font-size) !important;
            line-height: var(--h3-line-height);
          }
          & > h2 {
            font-size: 5.6rem !important;
            line-height: 6.2rem;
          }
        }
        .HeroDescriptionContainer {
          padding-top: 25px;
          & > p {
            font-size: var(--p-font-size);
            line-height: var(--h5-font-size);
            width: 100%;
          }
        }
      }
      .ButtonContainer {
        padding-bottom: 35px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        .HeroBtn {
          width: 95%;
        }
      }
    }
  }
}
