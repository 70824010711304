@import '../../sass/main.scss';

.segmentStyle.ui.segment{
    border: none;
    box-shadow: none;
    padding: 8rem 0;
    background: none;
}


.WhyChooseContainer{
    // font-family: Avenir Next;
    .componentTitle{
        letter-spacing: -0.048rem;
        margin-bottom: 3.2rem;
    }
    .titleAccompanyText{
        margin-bottom: 3.2rem;
    }
    .gridStyling{
        .gridColumn{
            padding: 0rem 2rem;
        }
        .gridTitle{
        }
        .description {
            color: $text-gray;
        }
    }
    .declarativeText{
        color: $text-gray;
        margin-top: 3.2rem;

    }

    .ButtonContainer {
      margin-top: 3rem;
      padding-bottom: 0;
    }
}

@include respond-to(mobile){
    .segmentStyle.ui.segment{
      padding: 0.8rem 2rem 6.4rem;
    }
    .WhyChooseContainer{
        .gridStyling{
            .gridColumn{
                padding: 1rem 2rem;
            }
        }
    }
}

@include respond-to(tablet){
    .segmentStyle.ui.segment{
        padding: 6.4rem 2rem 
    }
    .WhyChooseContainer{
        .gridStyling{
            .gridColumn{
                padding: 1rem 2rem;
            }
        }
    }
}