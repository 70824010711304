@import '../../../sass/main.scss';
.ChangeLocationTabs {
  height: inherit;
  z-index: 10;
  .ui.segment {
    border: 0 !important;
    box-shadow: none !important;
    margin: 0;
    border: 0;
    position: static;
    padding: 1em 0;
  }
  .ui.secondary.pointing.menu {
    margin-bottom: 3.8rem;
  }
  .item {
    font-size: 1.8rem;
    width: 50%;
    padding: 1rem !important;
    justify-content: center;
    border-bottom: 0.4rem solid var(--color-light-shaded-grey) !important;
  }
  .active.item {
    color: $brand-orange !important ;
    border-image: linear-gradient(90deg, var(--color-dark-shaded-orange) 0%, var(--color-light-shaded-orange) 100%) 30 !important;
    border-bottom: 0.4rem solid !important;
    font-family: var(--font-family-bold) !important;
  }
  .CenterListViewContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    @include respond-to(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
    .ListViewCenterCardContainer {
      margin: auto;
    }
  }
}
