@import '../../../sass/main.scss';

.ForgotPasswordForm {
  .forgotpassword__block--required--text {
    span {
      color: $dark-orange;
    }

    margin-bottom: 40px !important;

    .asteriskStyle {
      color: $gradient-start-color;
    }
  }

  .message.negative {
    margin-bottom: 4rem;
  }

  .ButtonContainer {
    margin-right: 20px
  }

  .UnderlineBlackButtonContainer {
    @include respond-to(mobile) {
      text-align: center;
      margin-top: 3rem;
    }

    @include respond-to(desktop) {
      display: inline-block;
    }

    @include respond-to(tablet) {
      display: inline-block;
    }
  }

  // .UnderlineBlackButton{
  //   color: $rich-black;
  //   text-decoration: underline;
  // }
  // .UnderlineBlackButton:hover,
  // .UnderlineBlackButton:active{
  //   color: $rich-black;
  //   text-decoration: underline;
  // }
  background-color: $white;
  padding-bottom: 214px !important;

  @include respond-to(mobile) {
    padding-bottom: 75px !important
  }

  .FormField {
    width: 100%;
  }

  .RequiredInfo {
    color: $dark-pink;
  }

  .MessageButtonContainer {
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 42px;
  }

  .ui.form .fields {
    margin-bottom: 24px !important;

    &.ButtonContainer {
      justify-content: left;
      margin-bottom: 23px;
      align-items: center;

      button {
        margin-right: 48px;
      }

      a {
        color: $text-gray;
        text-decoration: underline;
      }

      @include respond-to(mobile) {
        &.ButtonContainer {
          button {
            width: 100%;
            margin-right: 0;
            margin-bottom: 32px;
          }

          a {
            margin: 0 auto;
          }
        }
      }
    }
  }

}