@import '../../sass/main.scss';

.AccordionGridContainer {
  border-bottom: 1px solid $medium-gray;
  padding: 8px;

  .ui.accordion .title {
    display: flex;
    align-items: center;
    gap: 18px;
    justify-content: space-between;
    letter-spacing: -0.24px;
    .chevron-arrow {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .accordionContent {
    padding: 24px 0 48px 0 !important;
    .AccordionGridContentContainer {
      font-size: var(--p-font-size);
      line-height: var(--p-line-height);

      row-gap: 32px !important;
      ul {
        & > li {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
      .AccordionColumn {
        text-align: center;
        padding-top: 16px;
      }
    }
    .AccordionColumnContainer {
      display: flex !important;
      align-items: center;
      flex-direction: column;
    }
    .AccordionGridContentDescContainer {
      padding-top: 40px;
    }
  }
  .AccordionGridContentTextContainer {
    ul {
      margin-left: 1.5rem !important;
    }
    padding-top: 18px;
    padding-bottom: 32px;
  }
  .AccordionShortDescTextContainer {
    padding-bottom: 48px;
  }
}

@include respond-to(mobile-tablet) {
  .AccordionGridContainer {
    .accordionContent {
      width: 100%;
    }
  }
}
@include respond-to(mobile) {
  .AccordionGridContainer {
    .accordionContent {
      padding-top: 16px !important;
      padding-bottom: 32px !important;
    }
  }
}
