@import '../../sass/main.scss';

.main-container.separator-wrapper {
  max-width: unset;
  margin: 0 !important;
  padding-right: calc((100vw - 1008px) / 2);
  padding-left: calc((100vw - 1008px) / 2);
  @include respond-to(mobile) {
    padding: 0;
  }
}
