.ui.top.right.popup.transition.visible.NotificationPopup,
.ui.top.left.popup.transition.visible.NotificationPopup,
.ui.top.center.popup.transition.visible.NotificationPopup,
.ui.bottom.right.popup.transition.visible.NotificationPopup,
.ui.bottom.left.popup.transition.visible.NotificationPopup,
.ui.bottom.center.popup.transition.visible.NotificationPopup {
  background: var(--color-bg-light-blue);
  min-width: 385px;
  border-color: var(--color-bg-light-blue);
  box-shadow: none;
  padding: 2rem;
  z-index: 99;
  a {
    // color: $gray;
    color: #484848;
    text-decoration: underline;
  }
  .ui.two.column.grid {
    margin: 0px;
    .wide.column {
      padding: 0;
    }
  }

  @media screen and (max-width: 760px) {
    margin-left: 10rem;
  }
}
.ui.top.popup::before {
  background: var(--color-bg-light-blue);
}
.NotificationPopup {
  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    border-radius: 5;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: none;
    // border: 1px solid $green;
    border: 1px solid var(--color-dark-grey);
    background-color: var(--color-white);
  }
  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    // background-color: $green;
    background-color: var(--color-dark-grey);
    border: none;
  }
  .ui.checkbox .box:after,
  .ui.checkbox label:after {
    height: 24px;
    width: 24px;
    top: 2px;
    color: var(--color-white) !important;
  }
  .ui.checkbox .box,
  .ui.checkbox label {
    // color: $white;
    color: #ffffff;
    font-family: var(--font-family-regular);
    font-size: var(--caption-font-size);
    letter-spacing: 0;
    line-height: var(--caption-line-height);
    padding-left: 40px;
  }
}

#fav .CenterCardContainer.CenterCardSelected.card .content .header .HeaderWrapper .CheckMarkContainer .CheckMark,
#fav .CenterCardContainer.card:hover .content .header .HeaderWrapper .CheckMarkContainer .CheckMark {
  top: 0.4rem !important;
}

@media only screen and (max-width: 412px) {
  .ui.top.right.popup.transition.visible.NotificationPopup,
  .ui.top.left.popup.transition.visible.NotificationPopup,
  .ui.top.center.popup.transition.visible.NotificationPopup {
    min-width: 250px;
    max-width: 85vw;
  }
}

.StyledCheckBoxContent p {
  font-size: var(--caption-font-size);
  color: var(--color-rich-black);
  line-height: 2rem;
}
.content {
  font-size: var(--caption-font-size);
  color: var(--color-rich-black);
  line-height: 2rem;
}
