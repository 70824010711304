@import '../../sass/main.scss';

.PasswordLinkExpired {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  .HeroImageContainer {
    padding-bottom: 74px;
    img {
      width: 100%;
    }
  }
  .PasswordLinkExpiredTextContainer {
    max-width: 680px;
    margin: auto;
    text-align: center;
  }
  h4.ui.header.PasswordLinkExpiredHeader {
    color: var(--color-bl-blue);
    font-family: var(--font-family-bold);
    text-align: center;
  }
  .PasswordLinkExpiredRegularText {
    color: $gray;
    font-family: $font-family;
    font-size: var(--caption-font-size);
    letter-spacing: 0;
    line-height: var(--h5-line-height);
    padding-bottom: 20px;
    span {
      font-weight: bold;
    }
  }
}
