@import '../../sass/main.scss';
.news--container {
  .ui.input > input {
    border-radius: 0px 8px 8px 0px !important;
  }

  .ui.icon.input > i.icon {
    border-radius: 0px 8px 8px 0px !important;
  }

  .ui.search .prompt {
    border-radius: 0px 8px 8px 0px !important;
  }

  .ui.icon.input > i.link.icon {
    opacity: 1;
    background-color: #29398c;
    color: #fff;
    cursor: pointer;
  }
  // .ui.icon.input > input {
  //   padding: 0.5rem 1rem;
  // }
  .ui.input > input {
    height: 5.5rem;
    font-family: 'AvenirNextRegular' !important;
    font-size: var(--p-font-size);
  }

  // Blog Container

  .NoSearchResults {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 8px;
    p {
      margin-bottom: 0px;
      margin-left: 8px;
      color: #484848;
      font-size: --p-font-size;
      font-weight: 700;
      line-height: normal;
    }
    img {
      width: 24px;
    }
  }

  .NoResultsFound {
    padding: 0 171px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
    line-height: 0;
    p:last-child {
      font-family: 'AvenirNextRegular' !important;
      color: $rich-black;
    }
    @include respond-to(mobile) {
      padding: 0;
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }
}
