@import '../../sass/main.scss';

.error404Container {
  max-width: 664px;
  margin: auto;
  padding-top: 113px;
  padding-bottom: 170px;
  text-align: center;
  h2 {
    font-size: var(--h3-font-size);
    font-weight: bold;
    letter-spacing: -0.4px;
  }
  p {
    font-size: var(--p-font-size);
    letter-spacing: 0px;
    margin: 0;
  }
  .error404ImgContainer {
    img {
      margin: auto;
      width: 372px;
      height: 149px;
    }
  }
  .error404ShortDesc {
    padding: 24px 0;
    p {
      font-size: var(--h3-font-size);
      font-weight: bold;
    }
  }
  .error404Desc {
    padding-bottom: 40px;
  }
  .error404ViewSiteMap {
    padding-top: 40px;
    a {
      font-size: var(--p-font-size);
      font-weight: bold;
    }
  }
}
@include respond-to(tablet) {
  .error404Container {
    padding: 80px 0;
  }
}
@include respond-to(mobile) {
  .error404Container {
    margin: 0 auto;
    padding: 80px 24px 80px;
    .error404ImgContainer {
      img {
        width: 342px;
        height: 137px;
      }
    }
    .error404ShortDesc {
      padding-top: 36px 0;
    }
    .error404ButtonContainer button {
      width: 100%;
    }
  }
}
