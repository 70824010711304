@import '../../sass/main.scss';

.DonationProcess {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20%;
  border-radius: 0px 0px 200px 200px;
  
  .DonationProcessContainer {
    justify-content: center;

    .DonationProcessContentContainer {
      padding: 67px 0px 30px 0px;
      text-align: center;

      .DonationProcessDescriptionContainer {
        padding: 16px 86px 0px 86px;
      }
    }

    .DonationProcessGridColumn {
      .column:first-of-type {
        padding-left: 0px;
      }

      .DonationProcessPointsContainer {
        .DonationProcessPoints {
          display: flex;
          padding-bottom: 24px;

          .DonationProcessPointsIcon {
            min-height: 75px;
            min-width: 75px;
          }

          .DonationProcessPointsTitle {
            padding: 0 53px 0 24px;
          }
        }
      }

      .DonationProcessImgContainer {
        a {
          display: block;
          border-radius: 24px;
          overflow: hidden;
          
          .backdrop {
            position: absolute;
            width: calc(100% - 20px);
            height: 100%;
            background: rgba(0, 0, 0, 0.25);
            z-index: 1;
            border-radius: 24px;
            transition: all .5s ease;

            @include respond-to(mobile) {
              width: 100%;
            }
            
            &:hover, &:focus, &:active {
              background: transparent;

              & + img {
                transform: scale(1.2);
              }
            }
          }

          img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            transition: all .5s ease;
          }
        }
      }
    }

    .DonationProcessGridColumn.Reversed {
      flex-direction: row-reverse;
    }

    .ButtonContainer {
      padding-top: 22px;
    }
  }
}

@include respond-to(tablet) {
  .DonationProcess {
    .DonationProcessContainer {
      .DonationProcessGridColumn {
        padding-left: 87px;
        padding-right: 87px;
        gap: 32px;

        .DonationProcessPointsContainer {
          .DonationProcessPoints {
            .DonationProcessPointsTitle {
              padding: 0 0 0 16px;
              display: flex;
              flex-wrap: wrap;
              align-content: center;
            }
          }
        }
      }

      .DonationProcessGridColumn.Reversed {
        flex-direction: column-reverse;
      }
    }
  }
}

@include respond-to(mobile) {
  .DonationProcess {
    border-radius: 0;
    .DonationProcessContainer {
      .DonationProcessContentContainer {
        padding: 64px 0px 40px 0px;

        .DonationProcessDescriptionContainer {
          padding: 16px 0px 0px 0px;
          text-align: left;
        }
      }

      .DonationProcessGridColumn {
        gap: 32px;

        .DonationProcessPointsContainer {
          .DonationProcessPoints {
            .DonationProcessPointsIcon {
              min-height: 72px;
              min-width: 72px;
            }

            .DonationProcessPointsTitle {
              padding: 0 0 0 16px;
              display: flex;
              flex-wrap: wrap;
              align-content: center;
            }
          }
        }
      }

      .DonationProcessGridColumn.Reversed {
        flex-direction: column-reverse;
      }
    }
  }

  .ButtonContainer {
    padding-bottom: 35px;

    .DonationProcessBtn {
      width: 95%;
    }
  }

  .ui.grid > .row > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
}
