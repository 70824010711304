@import '../../../sass/main.scss';

.AccountCreateForm {
    .ui.form {
        .CentersCarouselContainer {
            .text-component__container {
                padding-top: 16px;

                @include respond-to(mobile) {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                @include respond-to(tablet) {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                .main-container {
                    padding-left: 0 !important;
                }
            }

            .CentersCarousel {
                padding-bottom: 5px;

                @include respond-to(tablet) {
                  padding-bottom: 7rem;
                }
                @include respond-to(mobile) {
                  padding-bottom: 10rem;
                  .CenterCardContainer{
                    max-width: 340px !important;
                    width: 100% !important;
                  }
                }
            }
        }

        .fields {
            margin-bottom: 22px;
        }

        .fields.ButtonContainer {
            justify-content: flex-end;
            margin-bottom: 80px;

            @include respond-to(mobile) {
                margin-bottom: 40px;
            }

            button {
                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        .main-container {
            @include respond-to(mobile) {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
        }

        .separator-wrapper {
            padding-left: 0;
            padding-right: 0;

            @include respond-to(tablet) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .Separator {
                margin-top: 40px !important;
                margin-bottom: 40px !important;

                @include respond-to(mobile) {
                    width: 100% !important;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                }
            }
        }

        .Separator+.fields {
            margin-top: 40px;
        }

        .IndicateRequiredText {
            margin-top: 64px;
            margin-bottom: 40px;
            font-size: $h5-font-size;

            span {
                color: $dark-orange;
            }
        }


        .FormNote {
            /*color: $dark-pink;*/
            font-size: $caption-font-size !important;
            padding-bottom: 30px;
        }


        .asterisk {
            small {
                top: -5px;
                font-size: $p-font-size;
                margin-right: 4px;
            }
        }

        .ui.disabled.input:has(#loginId) {
            opacity: 1;
        }

        #loginId {
            opacity: 1;
        }
    }

    .ValidationErrorBox {
        width: 632px;
        padding: 16px;
        border: 1px solid $darkRed;
        background-color: $validation-pink-background;
        color: $darkRed;
        border-radius: 0;
        margin-bottom: 32px;

        @include respond-to(mobile) {
            width: 310px;
        }
    }

    .NonEditableFieldGroup.fields {
        flex-direction: column;

        .NonEditableTextContainer {
            display: flex;
            align-items: center;
            padding-left: 0.5em;

            .NonEditableText {
                margin-left: 8px;
                font-size: $caption-font-size;
            }
        }
    }
}