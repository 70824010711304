@import '../../sass/main.scss';

.AccountCreate {
    .orange-banner {
        .header-container {
            .MainHeader {
                max-width: 836px;
                @include respond-to(mobile) {
                    max-width: 100%;
                }
            }
        }
        .subText {
            max-width: 664px;
            @include respond-to(mobile) {
                max-width: 100%;
            }
        }
    }
    div.TextComponent.main-container {
        padding-bottom: 32px;
    }
    .stepper {
        padding-bottom: 40px;
        @include respond-to(mobile) {
            justify-content: center;
        }
    }
    .Separator {
        margin: 40px auto;
        @include respond-to(mobile) {
            width: 100% !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
    .JoinMyBiolifeRewardsContainer {
      @include respond-to(mobile-tablet) {
        & > div.ui.grid {
          margin: 0 !important;
        }
      }
    }
}