@import '../../../sass/main.scss';

.ui.secondary.pointing.menu.SecondaryMenu {
  border: none;
  .item.SecondaryMenuItem {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
    font-weight: 200;
    margin: 0;
    &:hover {
      background-color: $light-gray;
    }
    &:active {
      color: $brand-orange;
      background-color: transparent;
    }
  }
}
.ui.secondary.vertical.pointing.menu {
  width: 100%;
}
