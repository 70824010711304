@import '../../sass/main.scss';

.orange-banner {
  padding: 80px 0px 80px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  @include respond-to(mobile) {
    border-radius: 0px 0px 125px 0px;
  }
  @include respond-to(desktop) {
    border-radius: 0px 0px 200px 0px;
  }
  @include respond-to(tablet) {
    border-radius: 0px 0px 200px 0px;
  }
  .header-container {
    opacity: 1;
    display: flex;
    align-items: center;
  }
  .MainHeader {
    div > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $h1-font-size;
      line-height: $h1-line-height;
      @include respond-to(mobile-tablet) {
        font-size: $h1-mobile-font-size;
        line-height: $h3-line-height;
      }
    }
  }
  .subText {
    text-align: left;
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    letter-spacing: 0px;
    color: $white;
    opacity: 1;
    padding-top: 16px;
  }
  .ui.button {
    width: 211px;
    height: 56px;
  }
  .buttonComponent {
    padding-top: 22px;
  }
  @include respond-to(tablet) {
    padding: 80px 0 80px 0px;
  }
  @include respond-to(mobile) {
    padding: 80px 0 80px 0px;

    .ui.button {
      width: 270px;
      height: 56px;
    }
  }
}
