@import '../../../sass/main.scss';

.SignUpForm {
  .ui.form {
    .fields {
      .FormField {
        padding-top: 8px;
        width: 100%;
      }
    }

    .SignUpFormTextContainer {
      .RequiredFieldText {
        font-size: var(--caption-font-size);
        line-height: var(--caption-line-heigh);
        padding-top: 4px;
        .asteriskStyle {
          color: $gradient-start-color;
        }
      }

      .DisclaimerText {
        padding-top: 40px;
        padding-bottom: 40px;
        & > :last-child {
          padding-top: 16px;
        }
      }
    }
  }
}
