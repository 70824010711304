@import '../../sass/main.scss';

.hero-temp1 {
  &__img-container {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include respond-to(mobile) {
        width: 39rem;
        height: 39rem;
      }
    }
  }
}
