@import '../../../sass/main.scss';

.ui.card.card-container2 {
  border-radius: 25px;
  min-height: 360px;
  box-shadow: none;
  margin: 0;
  padding: 25px;
  @include respond-to(mobile) {
    margin-top: 90px !important;
    padding: 0;
    height: 300px;
  }
  @include respond-to(tablet) {
    padding: 0;
  }
  .description {
    display: flex;
    justify-content: space-between;
    @include respond-to(tablet) {
      flex-direction: column-reverse;
      align-items: center;
      div {
        position: relative;
        bottom: 50px;
      }
    }
    @include respond-to(mobile) {
      flex-direction: column-reverse;
      align-items: center;
      div {
        height: 70%;
        position: relative;
        bottom: 50px;
      }
    }
    .component-title {
      width: 197px;
      padding: 25px 0;
      padding-bottom: 32px;

      @include respond-to(tablet) {
        width: unset;
      }
      @include respond-to(mobile) {
        width: unset;
        padding: 10px;
      }
    }
    .image-container {
      position: relative;
      bottom: 50px;
      @include respond-to(tablet) {
        bottom: 100px;
      }
      @include respond-to(mobile) {
        bottom: 100px;
      }
    }
  }
}
