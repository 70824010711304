@import '../../sass/main.scss';

.password-validation {
  margin-top: -24px;
  margin-bottom: 24px;
  .ListContent {
    display: flex;
    svg {
      width: 16px;
      height: 16px;
    }
    span,
    .errortext {
      font-size: var(--caption-font-size);
      margin-left: 8px;
    }
  }
  .password-validation__list > .ListContentContainer {
    margin-bottom: 5px;
  }
}
