// Color Palette
$Dark-khaki: darkkhaki;
$rich-black: #333333;
$dark-gray: #373b3f;
$dark-pink: #ac0079;
$light-blue: #6d76a7;
$dark-blue: #1f237a;
$gray: #484848;
$green: #009e8f;
$loyaltyDarkGreen: #007065;
$loyaltyLightGreen: #cce4e2;
$loyaltyLightGray: #f0eff1;
$loyaltyBlack: #222222;
$loyaltyLightGray: #f0eff1;
$white: #ffffff;
$yellow: #e6c153;
$light-green: #9dceca;
$orange: #eb6f24;
$blue: #2181bc;
$disable-gray: #acacac;
$black: #000000;
$light-gray-opacity: rgba(180, 180, 180, 0.06);
$light-gray-hero: #ebedf0;
$light-blue-hero: #d8daff;
$red: #e88084;
$bone-white: #fbf9f7;
$white-gray: #fafafa;
$facility-light-blue: #d8e9e8;
$donor-welcome-blue: #20247a;
$light-red: #f2dedf;
$dim-gray: #6d6d6d;
$new-gray: #eeedef;
$neutral-gray: #2d2d2d;
$lightGreen: #c0da69;
$lightRed: #e1242a;
$BlackShade: #2d2d2d;
$BlackLightShade: #333333;
$darkRed: #c70000;
$dark-focus-blue: #25358e;
$success-green: #a6ce39;
$extreme-light-blue: #ebf7fa;
$brand-orange: #f96506;
$brand-orange-hover: #c75105;
$brand-orange-secondary: #f9a13a;
$brand-blue: #29398c;
$light-gray: #ededed;
$medium-gray: #999999;
$text-gray: #333333;
$gradient-start-color: #ff4000;
$gradient-end-color: #f9a13a;
$social-icon-hover-color: #aee0ea;
$box-shadow-color: #33333340;
$card-hover-background-color: #f9f9f9;
$dark-orange: #ee4823;
$off-white: #f1f1f1;
$validation-pink-background: #c700001a;
$orange-gradient: linear-gradient(90deg, #ee4823 0%, #f9a13a 100%);
$header-shadow-color: #00000040;
$dark-solid-blue: #1a2563;

// Font
$font-family: 'AvenirNextRegular';
$font-family-bold: 'AvenirNextBold';
$font-bold: 700;
$font-extraBold: 800;

//Responsive Breakpoints
$breakpoint-mobile: 375px; //375px is the viewport width of iPhone SE
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1366px;

// Font Sizes and Line Heights
$caption-font-size: 1.6rem;
$caption-mobile-font-size: 1.4rem;
$caption-line-height: 2.4rem;
$caption-mobile-line-height: 2.1rem;

$p-font-size: 1.8rem;
$p-line-height: 2.7rem;

$h1-font-size: 5.6rem;
$h2-font-size: 4.8rem;
$h3-font-size: 4rem;
$h4-font-size: 3.2rem;
$h5-font-size: 2.4rem;
$h6-font-size: 2.4rem;

$h1-mobile-font-size: 4.4rem;
$h2-mobile-font-size: 4rem;
$h3-mobile-font-size: 3.2rem;
$h4-mobile-font-size: 2.4rem;
$h5-mobile-font-size: 1.8rem;
$h6-mobile-font-size: 1.8rem;

$h1-line-height: 7rem;
$h2-line-height: 6rem;
$h3-line-height: 5rem;
$h4-line-height: 4rem;
$h5-line-height: 3rem;
$h6-line-height: 3rem;

$h1-mobile-line-height: 5rem;
$h2-mobile-line-height: 4.6rem;
$h3-mobile-line-height: 3.8rem;
$h4-mobile-line-height: 3rem;
$h5-mobile-line-height: 2.4rem;
$h6-mobile-line-height: 2.4rem;

$padding-horizontal-desktop: 179px;
$padding-horizontal-tablet: 83px;
