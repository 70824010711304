@import '../../sass/main.scss';

.ui.modal.alert-modal {
  border-radius: 2.5rem !important;
  max-width: 83.6rem;

  p {
    text-align: left;
    width: 70.8rem;
  }

  .ui.button {
    display: block;
    border-radius: 8rem;
    max-width: 16rem;
    width: 100%;
    background-color: $Dark-khaki;
    font-size: 1.6rem;
    margin-top: 0.95rem;
    margin-bottom: 3.9rem;
  }

  .ui.button.primary {
    background-color: $brand-blue;
    border-width: 0.8rem;
    // border-style: solid;
    // border-color: #1a2563;
    height: 5.6rem !important;
    width: 16rem;

  }



  img.ui.image {
    display: flex;
    left: 1rem;
    width: 2.4rem;
  }

  .left.aligned.row {
    justify-content: flex-end;

    @include respond-to(mobile) {
      padding-right: 1rem !important;
    }
  }

  .actions {
    justify-content: space-around;
  }

  .actionButtons {
    min-width: 15rem !important;
  }

  .outlineButton {
    background-color: transparent !important;
    color: $dark-pink;
    border-width: 0.2rem;
    border-style: solid;
    border-color: $dark-pink;

    &:hover {
      color: $dark-pink !important;
    }
  }

  h3 {
    font-family: $font-family-bold !important;
    width: 70.8rem;

    @include respond-to(mobile) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .header {
    border-radius: 2.5rem;
    border-bottom: none;
    margin-left: 3.6rem;
    padding: 1rem 2rem 0rem 1rem;

    @include respond-to(mobile) {
      margin-left: 0rem;
      padding: 0 !important;
    }
  }

  .content {
    border-radius: 2.5rem;
    padding-top: 0.6rem;
    padding-left: 3.6rem;
  }
}