@import '../../../sass/main.scss';

.login-form__content {
  .form {
    .field {
      width: 100%;
    }
  }

  .timeout-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    .ui.label {
      width: 100%;
      text-align: center;
    }
  }

  .forgotPasswordButton,
  .ui.BlackUnderlineButton.button {
    padding-bottom: 30px !important;

    @include respond-to(mobile) {
      padding-top: 20px !important;
    }
  }

  .login-container {
    @include respond-to(mobile) {
      width: 100%;
    }

    max-width: 524px;

    .FormField {
      position: relative;

      small {
        top: -5px;
        font-size: $p-font-size;
      }

      small.TopLabel {
        position: absolute;
        color: $text-gray;
        font: normal normal bold 16px/24px Avenir Next;

        text-align: left;
        font: normal normal bold 16px/24px Avenir Next;
        letter-spacing: 0px;
        opacity: 1;

        &::after {
          content: '*';
          color: $brand-orange;
          padding-left: 5px;
        }
      }

      small.TopLabelError {
        position: absolute;
        font-size: 1.2rem;
        letter-spacing: 0.6px;
        line-height: 1.3rem;
        top: 0;

        &::after {
          content: '*';
          color: $brand-orange;
          padding-left: 5px;
        }
      }
    }
  }

  .FormNote {
    /*color: $dark-pink;*/
    font-size: var(--caption-font-size) !important;
    padding-bottom: 30px;
  }

  .MessageButtonContainer {
    width: 100%;

    @include respond-to(mobile) {
      & > div {
        margin-top: 3rem !important;
        padding-bottom: 1.5rem;
      }
    }
  }

  .ui.form .fields {
    margin-bottom: 24px !important;

    &.ButtonContainer {
      justify-content: left;
      display: inline-flex;

      @include respond-to(mobile) {
        display: flex;
      }

      padding-bottom: 2px;
      padding-left: 5px;
      align-items: center;

      button {
        margin-right: 48px;
      }

      a {
        color: $text-gray;
        text-decoration: underline;
      }

      @include respond-to(mobile) {
        &.ButtonContainer {
          button {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  .RegisterButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
  }

  .asterisk {
    small {
      top: -5px;
      font-size: $p-font-size;
      margin-right: 4px;
    }
  }
}
