@import '../../sass/main.scss';

.LoginContainer {
  margin-bottom: 40px;
  .Separator {
    border-color: #ededed !important ;
    @include respond-to(desktop) {
      max-width: 600px;
      margin-right: 40% !important;
    }
    @include respond-to(tablet) {
      margin-right: 35% !important;
    }
    @include respond-to(mobile) {
      width: 95% !important;
    }
  }
  .TextComponent {
    padding-top: 30px !important;
  }
  .text-component-description {
    width: 500px !important;
    @include respond-to(mobile) {
      width: 100% !important;
    }
    margin: 0 !important;
    a {
      color: #f96506 !important;
    }
  }
}
