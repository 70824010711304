@import '../../sass/main.scss';

.loggedInNavbarContainer {
  margin: 0 !important;
  border: none !important;
  box-shadow: 0px 4px 8px $header-shadow-color !important;
  .PrimaryMenu {
    border: none !important;
    border-radius: 0 !important;
    padding: 0;
    .segments {
      margin: 0;
    }
    .ui.menu {
      border: 0;
      margin: 0;
      box-shadow: none;
      align-items: center;
      font-size: 1.8rem !important;
      .NavbarMenuItem {
        &.item {
          padding: 0;
          margin: 0 15px;
        }
        &.active {
          background-color: transparent !important;
          border-bottom: 4px solid $brand-orange;
          margin-bottom: -4px;
        }
        border-radius: 0 !important;
        cursor: pointer;
        &:hover {
          color: $brand-orange !important;
          background-color: transparent !important;
        }
        &::before,
        ::after {
          width: 0;
        }
        .DropdownIcon {
          color: $brand-orange;
          margin: 0 0 0 2px;
        }
      }
      & a[role='button'] {
        font-family: inherit !important;
      }
    }
  }
}
