@import '../../../sass/main.scss';

.HomeCenterCardContainer.card {
  box-shadow: 0px 4px 8px $box-shadow-color !important;
  min-height: 150px !important;
  border-radius: 25px !important;
  width: 310px !important;
  @include respond-to(mobile) {
    width: 280px !important;
    margin: auto;
  }
  &:hover {
    cursor: pointer;
    .content .header .HeaderWrapper .CheckMarkContainer .CheckMarkIcon {
      left: 6px;
      top: 3px;
      svg path {
        fill: $light-gray;
      }
    }
    .content .description {
      background-color: $card-hover-background-color;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }
  .content {
    padding: 0;
    .header {
      background-color: $extreme-light-blue;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      .HeaderWrapper {
        display: flex;
        padding: 16px 24px;
        justify-content: space-between;
        .DistanceBox {
          display: flex;
          .LocationIconSmall {
            align-self: center;
            svg {
              width: 12px;
              height: 15px;
              path {
                fill: $dark-focus-blue;
              }
            }
          }
          .CenterDistance {
            font-size: $caption-font-size;
            align-self: center;
            margin-left: 7px;
            color: $dark-focus-blue;
            font-family: $font-family-bold !important;
          }
        }
        .CheckMarkContainer {
          background-color: $white;
          border-radius: 50%;
          border: 1px solid $medium-gray;
          width: 32px;
          height: 32px;
          position: relative;
          .CheckMarkIcon {
            position: absolute;
            left: 8px;
            top: 3px;
            svg path {
              fill: $white;
            }
          }
        }
      }
      .HeaderWrapper.HideDistanceBox {
        justify-content: flex-end;
      }
    }
    .header + .description {
      margin-top: 0;
      min-height: 353px;
      .segment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
        .CenterHeading {
          display: flex;
          justify-content: space-between;
        }
        .CenterName {
          margin-top: 2px;
          margin-bottom: 9px;
          color: $brand-blue;
          font-family: $font-family-bold !important;
        }
        .AddressBox {
          display: flex;
          align-items: baseline;
          .LocationIcon {
            align-self: flex-start;
            margin-top: 2px;
            svg {
              width: 16px;
              height: 20px;
              path {
                fill: $medium-gray;
              }
            }
          }
          .CenterAddress {
            font-size: $caption-font-size !important;
            margin-left: 7px;
          }
        }
        .PhoneNumberBox {
          display: flex;
          align-items: flex-end;
          margin-top: 16px;
          .PhoneIcon {
            svg {
              width: 16px;
              height: 20px;
            }
          }
          .PhoneNumber {
            font-size: $caption-font-size !important;
            margin-left: 7px;
            color: inherit;
          }
        }
        .IsSpanishCenter {
          margin-top: 28px;
          border-radius: 4px;
          background-color: $extreme-light-blue;
          width: max-content;
          padding: 4px 8px;
          color: $dark-focus-blue;
          font-size: $caption-mobile-font-size !important;
        }
        .CardSeparator {
          margin-top: 24px;
          margin-bottom: 24px;
          border: 1px solid $light-gray;
          width: 100%;
        }
        .NewDonorBonusInfo {
          font-size: $caption-font-size !important;
          max-width: 240px;
          font-family: $font-family-bold !important;
          color: $brand-orange;
          @include respond-to(mobile) {
            max-width: 262px;
          }
        }
        .ExpiryDate {
          color: $medium-gray;
          font-size: $caption-font-size !important;
          font-style: italic;
        }
      }
    }
  }
}
.HomeCenterCardContainer.HomeCenterCardSelected.card {
  .content .header {
    background-color: $brand-orange;
  }
  .content .header .HeaderWrapper {
    .CheckMarkContainer {
      border: none;
      .CheckMarkIcon {
        left: 8px;
        top: 3px;
        svg path {
          fill: $dark-focus-blue;
        }
      }
    }
  }
  .content .header .HeaderWrapper .DistanceBox {
    .LocationIconSmall {
      svg path {
        fill: $white;
      }
    }
    .CenterDistance {
      color: $white;
    }
  }
}

.HomeDescription {
  min-height: 0px !important;
}
