@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.center-meet-our-staff-container {
  padding: 25px 0 80px 0;
  .center-meet-our-staff-slider.slick-slider {
    svg {
      &:hover {
        fill: $brand-orange;
      }
    }
    margin: auto;
    @include respond-to(tablet) {
      width: 90%;
    }
    @include respond-to(mobile) {
      width: 90%;
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .meet-our-staff-card-container {
      width: 250px;
      margin: 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .staff-title {
        color: $brand-orange;
        padding: 10px 0;
      }
    }
    .slick-list {
      margin: 0 100px;
      @include respond-to(tablet) {
        margin: 0;
      }
      @include respond-to(mobile) {
        margin: 0;
      }
    }
    .slick-dots {
      bottom: -55px;
      li {
        width: 8px;
      }
      li button:before {
        background-color: black !important;
        opacity: 0.5;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: black !important;
        border-radius: 5px;
        opacity: 1;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      left: 10px;
      z-index: 99;
    }
    .slick-next {
      right: 10px;
      z-index: 99;
    }
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
  }
}

.center-meet-our-staff-container.LessDataWrapper {
  .slick-slider {
    .slick-track {
      margin-left: 0;
    }
    .slick-slide.slick-cloned {
      display: none;
      @include respond-to(mobile) {
        display: block;
      }
    }
  }
}
