@import '../../../sass/main.scss';

@mixin onAction-with-color($color) {
  color: $color !important;
  .arrow path:last-child {
    fill: $color !important;
  }
}

@mixin common-arrowButton-Style {
  vertical-align: middle;
  width: 16px;
  height: 16px;
}

.TransparentButton,
.ui.TransparentButton.button,
.ui.active.TransparentButton,
.ui.active.button.TransparentButton {
  color: $brand-orange;
  background-color: transparent;
  font-size: var(--p-font-size);
  font-weight: bold !important;
  padding: 0 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: transparent 1px solid;
}

.TransparentButton:hover,
.ui.TransparentButton:hover .ui.active.TransparentButton:hover,
.ui.active.button.TransparentButton:hover {
  color: $brand-orange !important;
}

.TransparentButton.Inverted {
  border-color: $brand-orange !important;
  color: $brand-orange !important;
}

.TransparentButton.Inverted:active {
  border-color: $brand-orange !important;
  color: white !important;
  background-color: $brand-orange !important;
}

.arrow {
  @include common-arrowButton-Style;
  margin-left: 0.5rem;
  path:last-child {
    fill: $brand-orange;
  }
}

.leftArrow {
  @include common-arrowButton-Style;
  margin-right: 0.5rem;
  path:last-child {
    fill: $dark-focus-blue;
  }
}

.leftArrow:hover {
  path:last-child {
    fill: $brand-orange;
  }
}

.BlueArrowTextButton,
.ui.BlueArrowTextButton.button,
.ui.active.BlueArrowTextButton,
.ui.active.button.BlueArrowTextButton {
  @include onAction-with-color($dark-focus-blue);
}
