@import '/../../sass/main.scss';

.CompensationAdvertisementContainer {
  @include respond-to(desktop) {
    & > div {
      max-width: 1366px;
      margin: 0 auto !important;
      align-items: flex-start !important;
    }

    &.ui[class*='middle aligned'].grid > .row > .six.computer {
      align-self: flex-start !important;
    }
  }
  .CompensationAdvertisementVerbiageContainer {
    padding-right: 178px !important;
    .CompensationAdvertisementTitle {
      padding: 16px 0;
      text-align: left;
    }
    .CompensationAdvertisementDescription {
      padding: 0 0 25px 0;
      * {
        font-size: $p-font-size;
      }
    }
    .ui.column {
      div:first-child {
        padding-bottom: 8px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }
    .ui.image {
      width: 120px;
      height: 120px;
    }
    .CompensationAdvertisementItemsContainer {
      .ui.button {
        margin: 38px 0px;
      }
      div > p,
      span {
        font-size: var(--p-font-size);
      }
    }
  }
  .Reversed {
    flex-direction: row-reverse !important;
  }
  .CompensationAdvertisementItemTitle {
    padding-bottom: 8px;
  }
}
@include respond-to(tablet) {
  .CompensationAdvertisementContainer {
    & > div {
      margin: 0 2.4rem;
    }
    .CompensationAdvertisementVerbiageContainer {
      padding-right: 48px !important;
    }
  }
}
@include respond-to(mobile) {
  .CompensationAdvertisementContainer {
    & > div {
      margin: 0 2.4rem;
    }
    .Reversed {
      flex-direction: column-reverse !important;
    }
    .CompensationAdvertisementVerbiageContainer {
      padding: 0 25px 25px !important;
      margin: auto !important;
      text-align: center;
      img {
        margin: auto;
      }
      button {
        margin: auto;
      }
      .CompensationAdvertisementTitle {
        padding-top: 0;
      }
      .CompensationAdvertisementDescription {
        text-align: left;
      }
    }
  }
}
