@import '../../sass/main.scss';

.activate-debit-card {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .activate-debit-card__container {
    max-width: var(--breakpoint-desktop);
    &--padding {
      padding: 38px 0 0 var(--padding-horizontal-desktop) !important;
      &--right {
        padding-left: 0px !important;
        padding-right: var(--padding-horizontal-desktop) !important;
      }
      &--left {
        padding-right: 0px !important;
        padding-left: var(--padding-horizontal-desktop) !important;
      }
    }
  }

  &__grid--normal > &__text {
    padding-right: 110px !important;
  }

  &__grid--reversed {
    > .activate-debit-card__text {
      padding-left: 110px !important;
    }
  }

  &__title {
    padding-bottom: 24px !important;
  }

  &__grid--reversed {
    flex-direction: row-reverse !important;
  }

  &__container.ui.grid {
    max-width: 1365px;
    margin: auto !important;

    @include respond-to(mobile-tablet) {
      padding: 38px 0px 0px !important;
    }
  }

  &__description {
    li {
      font-size: $p-font-size !important;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      &:last-child {
        margin-bottom: 16px;
      }
    }

    li {
      ul {
        li:first-child {
          padding-top: 16px;
        }
      }
    }

    table {
      td {
        vertical-align: top;
        ul, ol {
          li {
            margin-bottom: 1.6rem;
          }
        }
      }
    }
  }

  td {
    padding-bottom: 16px;
    &:not(:first-child) {
      padding-left: 48px;
    }
  }

  table {
    padding-top: 24px;
    margin-left: 1.6rem;
  }

  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding-left: 0;
    padding-right: 0;
  }

  &__grid--centered > &__text {
    padding-right: $padding-horizontal-desktop !important;
  }

  &__image {
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  &__stepper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 24px;

    &--title {
      font-family: $font-family-bold !important;
    }

    &--icon {
      height: 48px;
      width: 48px;
    }
  }
}

@include respond-to(tablet) {
  .activate-debit-card {
    &__grid--normal > &__text,
    &__grid--reversed > &__text,
    &__grid--centered > &__text {
      padding-right: 48px !important;
    }
    &__grid--reversed {
      padding-left: 0 !important;
    }
    &__grid--normal > &__text,
    &__grid--centered > &__text {
      padding-left: 48px !important;
    }
  }
}

@include respond-to(mobile) {
  .activate-debit-card {
    td {
      display: block;
      padding-top: 16px;
      &:not(:first-child) {
        padding-left: 0;
      }
    }
    &__container {
      padding: 12px 0 0 0 !important;
    }

    &__description {
      padding: 16px 0px 0px 0px;
      text-align: left;
      ul {
        padding-top: 16px;

        li:not(:first-child) {
          padding-top: 16px;
        }
      }

      li {
        ul {
          padding-left: 0;

          li {
            padding-top: 0;
          }
        }
      }

      ul, ol {
        margin-left: 1.6rem;
      }
    }

    &__grid {
      gap: 32px;
      flex-direction: column-reverse !important;
    }

    .ui.grid > .column:not(.row),
    .ui.grid > .row > .column {
      padding-left: 24px;
      padding-right: 24px;
    }

    &__image {
      padding-right: 0px !important;

      &--right {
        padding-left: 24px !important;
        padding-right: 0px !important;
      }

      &--left {
        padding-right: 24px !important;
        padding-left: 0px !important;
      }
    }
  }
}
