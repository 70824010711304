@import '../../sass/main.scss';

.PlasmaDonation {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond-to(desktop) {
    & > div.ui.grid {
      max-width: 1366px;
      margin: 0 auto !important;
    }
  }

  .PlasmaDonationGridColumn {
    display: flex;
    .PlasmaDonationContentContainer {
      padding: 3.8rem 11rem 3rem 0 !important;
      text-align: left;
      .PlasmaDonationTitleContainer {
        & > h2 {
          font-size: var(--h1-font-size);
          line-height: 6.2rem;
        }
      }
      .PlasmaDonationDescriptionContainer {
        padding-top: 16px;
        & > p {
          font-size: var(--p-font-size);
          line-height: var(--p-line-height);
        }
      }
      .PlasmaDonationShortDescriptionContainer {
        padding-top: 16px;
      }
      .ButtonContainer {
        padding-top: 48px;
        display: flex;

        button {
          margin-right: 2rem;

          @include respond-to(mobile) {
            margin-right: 0;
          }
        }
      }
    }
    .PlasmaDonationImgContainer {
      @include respond-to(desktop) {
        display: flex !important;
        justify-content: flex-end;
      }
      @include respond-to(tablet) {
        display: flex !important;
        align-items: flex-end;
      }

      img {
        width: auto;
        object-fit: contain;
        border-top-left-radius: 10rem;
        border-top-right-radius: 0;

        @include respond-to(mobile) {
          width: 100%;
        }
      }
    }
  }
  .ui.grid > .row {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: var(--padding-horizontal-desktop);

    &.row-reverse,
    &.column-reverse {
      padding-left: 0;

      .PlasmaDonationImgContainer {
        @include respond-to(desktop) {
          justify-content: flex-start;
        }
        @include respond-to(tablet) {
          display: flex !important;
          align-items: flex-end;
        }

        img {
          width: auto;
          border-top-left-radius: 0;
          border-top-right-radius: 10rem;

          @include respond-to(mobile) {
            width: 100%;
          }
        }
      }
    }

    @include respond-to(mobile-tablet) {
      padding-left: 0px;
    }
  }
  .ui.grid > .row > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@include respond-to(tablet) {
  .PlasmaDonation {
    .PlasmaDonationGridColumn {
      .PlasmaDonationContentContainer {
        padding: 0 2.4rem 4.8rem 4.8rem !important;
        .PlasmaDonationTitleContainer {
          width: 94%;
          & > h2 {
            font-size: var(--h1-mobile-font-size);
            line-height: var(--h1-mobile-line-height);
          }
        }
      }
    }
  }
}

@include respond-to(mobile) {
  .PlasmaDonation {
    padding: 0px 0px 0px 0px;
    .PlasmaDonationGridColumn {
      .PlasmaDonationContentContainer {
        padding: 40px 24px 48px 24px !important;
        .PlasmaDonationTitleContainer {
          & > h2 {
            font-size: var(--h2-mobile-font-size);
            line-height: var(--h2-mobile-line-height);
          }
        }
      }
      .PlasmaDonationImgContainer {
        background-color: #ffff;
      }
      .ButtonContainer {
        padding-bottom: 0;
        justify-content: center;
      }
    }
  }
}
