@import '../../../sass/main.scss';

.resetpassword__block{
  .resetpassword__block--required--text{
    font-size: $caption-font-size;
    span {
        color: $dark-orange;
    }
  }
  .resetpassword__block--validation__block{
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  .resetpassword__block--inputblock{
    margin-bottom: 2rem
  }
}