@import '../../sass/main.scss';

.IntegrationTextContentContainer {
  width: 1008px;
  margin: 0 auto;
  @include respond-to(mobile) {
    max-width: 90%;
  }
  @include respond-to(tablet) {
    max-width: 90%;
  }
}
