@import '../../sass/main.scss';

.WhatToDoContanier {
  .manageGridColumnPadding {
    @include respond-to(mobile) {
      margin-bottom: 5rem !important;
    }

    @include respond-to(tablet) {
      margin-bottom: 5rem !important;
      padding-left: 0 !important;
      padding-right: 2.5rem !important;
      width: 100% !important;
    }

    @include respond-to(desktop) {
      padding-left: 0 !important;
      padding-right: 2.5rem !important;
      margin-bottom: 1rem !important;
    }
  }

  @include respond-to(tablet) {

    .ui.grid>.column.row>[class*='eight wide computer'].column,
    .ui.grid>.row>[class*='eight wide computer'].column {
      width: 100%;
    }
  }

  .mainText {
    letter-spacing: -0.48px;

    * {
      font: normal normal bold 48px/54px;
    }
  }

  .headingText {
    text-align: left;
    font: normal normal bold 32px/38px;
    letter-spacing: -0.32px;
    margin-bottom: 1rem;
  }

  .paragraph {
    margin-bottom: 10px;
    font: normal normal normal 18px/26px;
    letter-spacing: 0px;
    min-height: 100px;

    @include respond-to(tablet) {
      min-height: 70px;
    }

    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }

  .IconImageContainer {
    display: flex;

    .IconImage {
      width: auto;
      margin-right: 20px;
    }
  }
}

.MyBalanceContainer {
  .WhatToDoContanier {
    .ui.grid>.row {
      justify-content: space-evenly !important;
    }

  }
}