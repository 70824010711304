@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.center-photos-container {
  padding: 25px 0 100px 0;
  .center-photos-slider.slick-slider {
    svg {
      &:hover {
        fill: $brand-orange;
      }
    }
    margin: auto;
    width: 100%;
    @include respond-to(tablet) {
      height: 360px;
    }
    @include respond-to(mobile) {
      height: 175px;
    }
    .slick-list {
      img {
        height: 550px;
        @include respond-to(tablet) {
          height: 360px;
          object-fit: cover;
        }
        @include respond-to(mobile) {
          height: 175px;
          object-fit: cover;
        }
      }
    }
    .slick-dots {
      bottom: -50px;
      li {
        width: 8px;
      }
      li button:before {
        background-color: black !important;
        opacity: 0.5;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: black !important;
        border-radius: 5px;
        opacity: 1;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      left: 10px;
      z-index: 99;
    }
    .slick-next {
      right: 10px;
      z-index: 99;
    }
  }
  .slick-arrow {
    top: 106%;
    height: 50px;
    width: 50px;
  }
}
