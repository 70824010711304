@import '../../sass/main.scss';

.find-your-centre-banner {
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;

  ul,
  ol {
    list-style-position: outside;
    margin-left: 1.8rem;

    @include respond-to(mobile) {
      margin-left: 3rem;
    }
  }

  table tr td {
    padding-right: 1rem;
  }

  .find-your-centre-banner-grid {
    align-items: center;
    margin: 0 auto;
    .title {
      div > h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 29px 0;
      }
    }
    .title {
      padding-left: 0 !important;
    }
    strong {
      font-weight: inherit !important;
    }
    .input {
      height: 56px;
      width: 100%;
      .TextButtonContainer {
        width: 100%;
      }
    }
    .fields {
      display: block;
      .SearchInputButton {
        padding-left: 100px;
        .FieldLabel {
          display: inline !important;
        }
      }
    }
    .find-your-centre-banner-row {
      @include respond-to(mobile) {
        width: 90%;
        margin: auto;
      }
    }
  }
  .ui.input > input {
    border: 0px !important;
  }
  @include respond-to(tablet) {
    .title {
      div > h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 39px 0 !important;
      }
    }
    .fields {
      padding-left: 0px !important;
      .SearchInputButton {
        padding-left: 25px !important;
      }
    }
  }
  @include respond-to(mobile) {
    .title {
      padding: 0 0 32px 0 !important;
    }
    .title {
      div > h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 30px 0 0 0 !important;
      }
    }
    .fields {
      padding: 0 !important;
      justify-content: normal !important;
      .SearchInputButton {
        padding-left: 0px !important;
        padding-bottom: 18px !important;
      }
    }
  }
  .useCurrentLocation {
    margin: -0.4rem 9.5rem;
    display: flex;
    @include respond-to(mobile) {
      margin: -0.6rem -0.2rem;
    }
    @include respond-to(tablet) {
      margin: -0.4rem 2rem;
    }
  }
  .useCurrentLocationLink {
    font-family: 'AvenirNextMedium' !important;
    color: black;
    font-size: $caption-mobile-font-size;
    margin-left: 1rem;
    cursor: pointer;
  }
}
