@import '../../sass/main.scss';

.donation-impact-process {
  display: flex;
  align-items: center;
  justify-content: center;

  &__title-container {
    padding-bottom: 10px !important;
    letter-spacing: -0.48px;
  }

  &__container {
    padding: 77px 0px 80px 0px !important;
  }
  &__grid-column {
    .column:first-of-type {
      padding-left: 0px;
    }

    &--reversed {
      flex-direction: row-reverse !important;
    }
  }

  &__img-container {
    padding-right: 14px !important;
  }
  &__img-container {
    &--centerAligned {
      .ui.grid > .row > [class*='five wide computer'].column {
        width: 100% !important;
      }
    }
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  @include respond-to(mobile) {
    &__container {
      padding: 64px 0px !important;
    }

    &__description-container {
      padding: 1rem 0 0;
      text-align: left;

      h5 {
        font-size: $h5-font-size !important;
      }
    }

    &__grid-column {
      gap: 32px;

      &--reversed {
        flex-direction: column-reverse !important;

        .donation-impact-process__img-container {
          padding-bottom: 3.8rem;
        }
      }
    }

    &__img-container {
      padding-right: 0px !important;
    }

    .ui.grid > .row > .column {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
