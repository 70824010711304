@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.nearby-centers-list {
  width: 1008px;
  margin: auto;
  @include respond-to(tablet) {
    width: 90%;
  }
  @include respond-to(mobile) {
    width: 90%;
  }
  .slick-slider {
    @include respond-to(tablet) {
      width: 90%;
      margin: auto;
    }
    @include respond-to(mobile) {
      width: 90%;
      margin: auto;
    }
    .card {
      border-radius: 25px;
      margin: 30px 15px;
      @include respond-to(tablet) {
        margin: 30px auto;
      }
      @include respond-to(mobile) {
        margin: 30px auto;
      }
    }
    .slick-dots {
      li {
        width: 8px;
      }
      li button:before {
        background-color: black !important;
        opacity: 0.5;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: black !important;
        border-radius: 5px;
        opacity: 1;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      left: -5%;
      z-index: 99;
    }
    .slick-next {
      right: -5%;
      z-index: 99;
    }
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
  }
}

.nearby-centers-list.LessDataWrapper {
  .slick-slider {
    .card {
      margin: 30px 5px;
      @include respond-to(mobile) {
        margin: 30px auto;
      }
    }
    .slick-track {
      margin-left: 0;
    }
    .slick-slide.slick-cloned {
      display: none;
      @include respond-to(mobile) {
        display: block;
      }
    }
  }
}
