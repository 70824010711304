@import '../../../../sass/main.scss';
.iconImg {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 3px solid $medium-gray;
  display: flex;
  margin-bottom: 3rem;
  &.activeIcon {
    border: 3px solid $brand-orange;
  }
  .numText {
    font-size: 2.4rem;
    margin: auto;
    font-family: 'AvenirNextBold' !important;
    color: $medium-gray;
  }
  .activeStep {
    color: $brand-orange;
  }
}

.completeIcon {
  background-color: $success-green;
  border: 3px solid $success-green !important;
  .checkmark {
    display: inline-block;
    transform: rotate(45deg);
    height: 3.3rem;
    width: 1.5rem;
    margin-left: 35%;
    margin-top: 7px;
    border: none;
    border-bottom: 0.6rem solid $white;
    border-right: 0.6rem solid $white;
    background: $success-green;
    position: relative;
  }
}
