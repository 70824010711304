@import '../../sass/main.scss';

.AvailableAppointmentCardContainer.card {
  box-shadow: 0px 4px 8px $box-shadow-color;
  border-radius: 25px;
  min-width: 320px;
  &:hover {
    cursor: pointer;
    .content .header .HeaderWrapper .CheckMarkContainer .CheckMark {
      left: 6px;
      top: 9px;
      svg path {
        fill: $light-gray;
      }
    }
    .content .description {
      background-color: $card-hover-background-color;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }
  .content {
    padding: 0;
    .header {
      background-color: $brand-orange;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      .HeaderWrapper {
        display: flex;
        height: 54px;
        padding: 16px 24px;
        justify-content: space-between;
        flex-direction: row-reverse;
        .DistanceBox {
          display: flex;
          .LocationIconSmall {
            align-self: center;
            svg {
              width: 12px;
              height: 15px;
              path {
                fill: $white;
              }
            }
          }
          .CenterDistance {
            font-size: $caption-font-size;
            align-self: center;
            margin-left: 7px;
            color: $white;
            font-family: $font-family-bold !important;
          }
        }
      }
    }
    .header + .description {
      margin-top: 0;
      .segment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        margin: 0;

        .CenterBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          .CenterName {
            color: $brand-blue;
            font-family: $font-family-bold !important;
            margin: 0;
          }
          .HomeCenterIcon {
            align-self: flex-start;
            margin-top: 2px;
            svg {
              width: 28px;
              height: 28px;
              path {
                // fill: $brand-orange;
              }
            }
          }
        }
        .AddressBox {
          display: flex;
          align-items: baseline;
          margin-top: 16px;
          .LocationIcon {
            align-self: flex-start;
            margin-top: 2px;
            svg {
              width: 16px;
              height: 20px;
              path {
                fill: $brand-orange;
              }
            }
          }
          .CenterAddress {
            font-size: $caption-font-size !important;
            margin-left: 8px;
          }
        }
        .PhoneNumberBox {
          display: flex;
          align-items: flex-end;
          margin-top: 16px;
          .PhoneIcon {
            svg {
              width: 16px;
              height: 20px;
              path {
                fill: $brand-orange;
              }
            }
          }
          .PhoneNumber {
            font-size: $caption-font-size !important;
            margin-left: 8px;
            color: inherit;
          }
        }
        .IsSpanishCenter {
          margin-top: 24px;
          border-radius: 4px;
          background-color: $extreme-light-blue;
          width: max-content;
          padding: 4px 8px;
          color: $dark-focus-blue;
          font-size: $caption-mobile-font-size !important;
        }
      }
    }
  }
}
