@import '../../sass/main.scss';

.verify-email-page {
  .text-media_container {
    .text-media__grid-column {
      @include respond-to(tablet) {
        display: flex;
        flex-direction: column;
      }
    }
    .text-media__img-container {
      a {
        width: 342px;
        height: 238px;
        overflow: hidden;
        display: block;
        border-radius: 24px;
        position: relative;

        @include respond-to(mobile) {
          width: 100%;
          height: 100%;
        }

        .backdrop {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.25);
          z-index: 1;
          border-radius: 24px;
          transition: all .5s ease;

          @include respond-to(mobile) {
            width: 100%;
          }
          
          &:hover, &:focus, &:active {
            background: transparent;

            & + img {
              transform: scale(1.2);
            }
          }
        }

        img.ui.image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all .3s ease;

          &:hover, &:focus, &:active {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @include respond-to(mobile) {
    .separator-wrapper {
      .Separator {
        width: 100% !important;
      }
    }
  }
}