@import '../../../sass/main.scss';

.ShareWithUsForm {
  padding-bottom: 50px;
  @include respond-to(desktop) {
    margin: 0 auto !important;
    max-width: 1008px;
  }
  @include respond-to(tablet) {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  @include respond-to(mobile) {
    padding-bottom: 18px;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .TopLabel {
    display: flex;
    font: normal normal bold 16px/24px Avenir Next;
  }
  .CheckboxContainer .custom-checkbox {
    margin-top: 22px;
    width: 70% !important;
    .InfoIcon {
      img.ui.image {
        width: 9rem;
        height: 2.4rem;
        top: 1rem;
        @include respond-to(mobile) {
          width: 10rem;
        }
      }
    }
    span.caption-text {
      @include respond-to(mobile) {
        font-size: var(--p-font-size) !important;
      }
    }
    @include respond-to(mobile) {
      width: 98% !important;
    }
  }
  .ui.fluid.button {
    @include respond-to(mobile) {
      width: 90%;
    }
  }
}
