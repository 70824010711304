@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.center-page-top-component-container {
  display: flex;
  width: 1008px;
  @include respond-to(tablet) {
    width: 90%;
  }
  @include respond-to(mobile) {
    width: 90%;
    flex-direction: column;
  }
  margin: auto;
  .ui.table.center-hours-container {
    width: 50%;
    height: fit-content;
    @include respond-to(mobile) {
      width: 100%;
      tr {
        box-shadow: none !important;
      }
      td {
        display: inline-block !important;
        width: 50% !important;
      }
    }
    border: none !important;
    td {
      border: none !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .center-seo-container {
    width: 50%;
    @include respond-to(mobile) {
      width: 100%;
    }
    .center-testimonial {
      width: 100%;
      min-height: 250px;
      padding: 10px;
      border-radius: 25px;
      .testimonial-patient-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        .patient-name {
          color: $orange;
        }
        p {
          margin: 0 !important;
          font-size: $caption-font-size ;
        }
      }
      .header {
        margin: 15px 0 0 15px;
      }
      .content {
        border: none !important;
      }
    }
  }
}
