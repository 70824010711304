.ui.card.card-container1 {
  border-radius: 25px;
  min-height: 360px;
  box-shadow: none;
  margin: 0;
  padding: 25px;
  .card-title {
    padding: 25px 0;
    padding-bottom: 32px;

    *:first-child {
      padding-bottom: 16px;
    }
  }
  .card-description {
    padding: 24px 0 25px 0;
  }
}
