@mixin respond-to($breakpoint) {
    @if $breakpoint == mobile {
      @media (max-width: #{$breakpoint-tablet - 1px}) {
        @content;
      }
    }

    @if $breakpoint == mobile-tablet {
      @media (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
      }
    }

    @if $breakpoint == tablet {
      @media (min-width: $breakpoint-tablet) and (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
      }
    }
  
    @if $breakpoint == desktop {
      @media (min-width: $breakpoint-desktop) {
        @content;
      }
    }
}