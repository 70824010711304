@import '../../../sass/main.scss';

.PrimarySelect {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  .InfoIcon {
    margin-left: 8px;
    align-self: center;
  }
  .ui.selection.dropdown.Base {
    height: auto !important;
    line-height: var(--p-line-height);
    padding-left: 1.6rem;
    padding-right: 10px;
    border-radius: 0;
    border: 1px solid $medium-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--p-font-size);
    color: $dark-focus-blue;
    gap: 1rem;

    i.icon {
      line-height: var(--p-line-height);
      float: right;
      color: $gray;
      font-size: 2rem;
    }
    .item {
      font-size: var(--p-font-size);
      line-height: var(--p-line-height);
      padding: 1rem 1.6rem !important;

      &:hover {
        background-color: $off-white;
        color: $dark-focus-blue;
      }
    }
    .item.selected,
    .item.active.selected {
      color: $dark-focus-blue;
      background-color: $white;
      &:hover {
        background-color: $off-white;
      }
    }

    &.active,
    &.value-selected {
      i.icon {
        color: $dark-focus-blue;
      }
    }
    &.no-value {
      color: transparent;

      &::selection {
        color: transparent;
        background: transparent;
      }
    }
  }
  .ui.selection.active.dropdown.Base .menu {
    border: 1px solid $medium-gray !important;
    max-height: 17.3rem;
    margin-top: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  //Below styles can be removed if it's not being used
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $gray;
  }
  .ui.selection.dropdown.Base.Error {
    .default.text {
      color: #ebcac8;
    }
  }
  .ui.dropdown .menu > .divider {
    margin: 0;
  }
  .ui.dropdown .menu .header {
    font-family: $font-family-bold !important;
    font-size: $h6-mobile-font-size;
    margin: 1.6rem 0;
    padding: 0 1.6rem;
  }
}
