@import '../../sass/main.scss';

.onMapView {
  min-height: 270px !important;
  .header + .description {
    min-height: 250px;
  }
}
.default {
  min-height: 350px !important;
  .header + .description {
    min-height: 353px;
  }
}
.ListViewCenterCardContainer.card {
  margin: 0;
  box-shadow: 0px 4px 8px #33333340 !important;
  // min-height: 350px !important;
  border-radius: 25px !important;
  width: 310px !important;

  @include respond-to(tablet) {
    width: 280px !important;
  }

  @include respond-to(mobile) {
    width: 270px !important;
  }

  &:hover {
    cursor: pointer;

    .content .header .HeaderWrapper .CheckMarkContainer .CheckMark {
      svg path {
        fill: #ededed;
      }
    }

    .content .description {
      background-color: #f9f9f9;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }

  .content {
    padding: 0;

    .header {
      background-color: $brand-orange;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;

      .HeaderWrapper {
        display: flex;
        padding: 16px 24px;
        justify-content: space-between;
        flex-direction: row-reverse;

        .DistanceBox {
          display: flex;

          .LocationIconSmall {
            align-self: center;

            svg {
              width: 12px;
              height: 15px;

              path {
                // fill: #25358e;
              }
            }
          }

          .CenterDistance {
            align-self: center;
            margin-left: 7px;
            color: white;
            font-family: 'AvenirNextBold' !important;
            font-size: 14px;
          }
        }

        .CheckMarkContainer {
          background-color: #fff;
          border-radius: 50%;
          border: 1px solid #999999;
          width: 32px;
          height: 32px;
          position: relative;

          .CheckMark {
            position: absolute;
            left: 6px;
            top: 3px;

            svg path {
              fill: #fff;
            }
          }
        }
        .CloseButton {
          display: flex;
        }
      }
    }

    .header + .description {
      margin-top: 0;

      .segment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;

        .CenterName {
          margin-top: 2px;
          margin-bottom: 9px;
          font-size: var(--p-font-size);
          color: #29398c;
          font-family: 'AvenirNextBold' !important;
          font-weight: 700;
        }

        .AddressBox {
          display: flex;
          align-items: baseline;

          .LocationIcon {
            align-self: flex-start;

            svg {
              width: 16px;
              height: 20px;

              path {
                fill: #999999;
              }
            }
          }

          .CenterAddress {
            font-size: var(--caption-font-size);
            margin-left: 7px;
          }
        }

        .PhoneNumberBox {
          display: flex;
          margin-top: 16px;

          .PhoneIcon {
            svg {
              width: 16px;
              height: 20px;
            }
          }

          .PhoneNumber {
            font-size: var(--caption-font-size);
            margin-left: 7px;
            color: inherit;
          }
        }

        .IsSpanishCenter {
          margin-top: 28px;
          border-radius: 5px;
          background-color: #ebf7fa;
          width: max-content;
          padding: 4px 8px;
          color: var(--color-bl-blue);
          font-size: var(--caption-mobile-font-size);
          font-family: var(--font-family-medium) !important;
        }

        .Separator {
          margin-top: 24px;
          margin-bottom: 24px;
          border: 1px solid #ededed;
          width: 100%;
        }

        .NewDonorBonusInfo {
          font-size: var(--caption-font-size);
          max-width: 240px;
          font-family: AvenirNextBold;
          color: #f96506;
        }

        .ExpiryDate {
          color: #999999;
          font-size: var(--caption-font-size);
          font-style: italic;
        }
      }
    }
  }
}

.ListViewCenterCardContainer.CenterCardSelected.card {
  .content .header {
    background-color: #f96506;
  }

  .content .header .HeaderWrapper {
    .CheckMarkContainer {
      border: none;

      .CheckMark {
        left: 7px;
        top: 4px;

        svg path {
          fill: #25358e;
        }
      }
    }
  }

  .content .header .HeaderWrapper .DistanceBox {
    .LocationIconSmall {
      svg path {
        fill: #fff;
      }
    }

    .CenterDistance {
      color: #fff;
    }
  }
}
