.formContainer {
  width: 80%;

  .formInfo {
    margin: 30px auto;

    h3 {
      margin-bottom: 25px;
      color: #28398c;
      font-weight: 900;
    }

    p {
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 764px) {
  .UpdateModal.ui.active.modal {
    width: 90%;
  }
}

//PD-4921-spacing for the modal confirm button for smaller screen
@media only screen and (max-width: 420px) {
  .formContainer {
    width: 80%;
  }
}
