@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.ui.grid.bonus-grid-container {
  .bonus-title {
    padding-bottom: 1px !important;
  }
  padding-top: 80px !important;
  .two.column.row {
    width: 1008px !important;
    margin: auto;
    @include respond-to(tablet) {
      margin: 0 50px;
      .column {
        padding: 0px 35px;
      }
    }
    @include respond-to(mobile) {
      width: 90% !important;
      .column {
        margin: 25px 0 !important;
      }
    }
    .column {
      .icon {
        color: unset;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 2.5rem 1.2rem;
        padding: 0;

        p {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          width: 85px;
          height: 85px;
          border-radius: 50%;
          border: 3.5px solid $brand-blue;
          outline: 12.5px solid $brand-orange-secondary;
          margin: 0;

          h5 {
            margin: 0;
            font-family: var(--font-family-bold) !important;
          }
        }
      }
      div {
        padding: 15px 0;
      }
      a,
      button {
        margin: 10px 0;
        margin-top: 17px;
      }
    }
  }
}
