@import '../../../sass/main.scss';

.TestimonialCardContainer.card {
  box-shadow: 0px 4px 8px #33333340 !important;
  border-radius: 25px !important;
  max-width: 300px !important;
  .content {
    padding: 24px;
    .header {
      p {
        height: 30px;
        font-size: 50px !important;
        background: $orange-gradient;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .description {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0 !important;
    }
    .PatientVerbiage {
      *{
        font-size: $caption-font-size !important;
      }
      min-height: 225px;
      padding: 1em 0 0;
    }
    .PatientDetails div{
      padding-bottom: 0;
      > div.wide.column {
        &:first-child {
          padding-left: 0;
          padding-right: 0;
        }
        &:last-child {
          padding-right: 0;
          @include respond-to(mobile) {
            padding-left: 19px;
        }
        }
      }
      h5 {
        color: $brand-orange;
        font-family: $font-family-bold !important;
        font-size: $caption-font-size !important;
      }
      p{
        font-size: $caption-font-size !important;
      }
      color: black;
    }
  }
}
