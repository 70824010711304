@import '../../sass/main.scss';

.text-media {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 86px;
  border-radius: 0px 0px 200px 200px;
  &__title-container {
    padding-bottom: 16px;
  }
  &__process-container {
    justify-content: center;
  }
  &__description-container {
    p {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
      &:last-of-type {
        margin-bottom: 16px;
      }
    }
    ul {
      list-style-position: outside;
      padding-left: 16px;

      & > li {
        padding-bottom: 16px;
      }
    }
  }
  &__grid-column {
    &--centerAligned {
      display: flex !important;
      justify-content: center !important;
      // align-items: center !important;
      flex-direction: column !important;
      padding-top: 0px !important;
    }
    &--reversed {
      flex-direction: row-reverse !important;
    }
    .column:first-of-type {
      padding-left: 0 !important;
    }
    &.top-aligned {
      flex-direction: column-reverse !important;
    }
  }

  &__img-container {
    padding-right: 24px !important;
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
    &--centerAligned {
      .ui.grid > .row > [class*='five wide computer'].column {
        width: 100% !important;
      }
      img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        padding-top: 47px;
      }
    }
  }
}
.donation-process-points {
  display: flex;
  padding-bottom: 24px;

  &__icon {
    min-height: 75px;
    min-width: 75px;
  }

  &__title {
    padding: 0 53px 0 24px;
  }
}

@include respond-to(tablet) {
  .text-media {
    &__grid-column {
      .column:first-of-type {
        padding-left: 0px;
      }
    }
  }
}

@include respond-to(mobile) {
  .text-media {
    padding-bottom: 135px !important;
    border-radius: 0px 0px 100px 100px;
    &__grid-column {
      .column:first-of-type {
        padding-left: 0 !important;
      }
      &--centerAligned {
        display: flex !important;
        justify-content: center !important;
        // align-items: center !important;
        flex-direction: column !important;
      }
      &--reversed {
        flex-direction: column-reverse !important;
      }
    }
    &__img-container {
      padding-right: 0px !important;
      padding-bottom: 24px !important;
    }
    .backdrop {
      padding-top: 32px;
    }
  }

  .ui.grid > .row > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
}
