@import '../../../sass/main.scss';

.SecondTestimonialContainer {
  width: 920px;
  display: flex;
  padding: 22px 37px 22px 52px;
  @include respond-to(tablet) {
    padding: 32px 30px;
    width: 100%;
    display: flex;
  }

  @include respond-to(mobile) {
    width: 100%;
    display: block;
    padding: 10px 5px;
  }
  .SecondTestimonialTemplateHeader {
    display: flex;
    @include respond-to(tablet) {
      text-align: left;
    }
    @include respond-to(mobile) {
      display: block;
      text-align: left;
    }
    .quotes-icon {
      width: 48px;
      height: 37px;
      @include respond-to(mobile) {
        height: 50px;
        width: 50px;
        margin-top: 12px;
      }
    }
  }
  .SecondTestimonialContent {
    padding-left: 24px;
    @include respond-to(mobile) {
      padding-left: 0px;
      text-align: left;
    }
  }
  .testimonial-description {
    padding-top: 20px;
    font-size: var(--h5-font-size) !important;
    word-wrap: break-word;
    white-space: pre-line;
    @include respond-to(mobile) {
      font-size: 2rem !important;
      word-wrap: break-word;
      white-space: pre-line;
      width: 100%;
    }
  }
  .testimonial-details {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    @include respond-to(mobile) {
      display: block;
    }
    .title-section {
      .component-title {
        text-align: left;
        font-size: var(--p-font-size) !important;
        font-family: var(--font-family-bold) !important;
        letter-spacing: 0px;
        color: #f96506;
        padding-bottom: 2px;
      }
      .component-subtitle {
        text-align: left;
      }
    }
    .ReadMoreBtn {
      padding-top: 14px;
      @include respond-to(mobile) {
        padding-top: 32px;
      }
    }
  }
}
