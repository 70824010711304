@import '../../sass/main.scss';

.JoinMyBiolifeRewardsContainer {
  margin-top: 12px;
  @include respond-to(desktop) {
    & > div.ui.grid {
      max-width: 1366px;
      margin: 0 auto !important;
    }
  }
  @include respond-to(mobile-tablet) {
    & > div.ui.grid {
      margin: 0 2.4rem !important;
    }
  }
  .JoinMyBiolifeRewardsWrapper {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    @include respond-to(mobile) {
      flex-direction: column !important;
    }
    .ContentBox {
      @include respond-to(mobile) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .ContentTitle {
        h2 {
          font-size: $h3-font-size !important;
          @include respond-to(mobile) {
            font-size: $h3-font-size !important;
            line-height: $h3-line-height !important;
          }
        }
      }
      .ContentDescription {
        margin-bottom: 35px;
        @include respond-to(mobile) {
          font-size: $p-font-size !important;
          line-height: $p-line-height !important;
          margin-top: 10px;
        }
      }
      .custom-checkbox {
        margin-bottom: 20px;
        margin-left: -0.5em;
      }
    }
  }
  .JoinMyBiolifeRewardsWrapper.Reversed {
    flex-direction: row-reverse;
    .ContentBox {
      padding-left: 0;
      .segment {
        padding-left: 0;
      }
    }
  }
}
