@import '../../sass/main.scss/';

.CheckEmailContainer {
  .form-confirmation-description {
    @include respond-to(desktop){
      width: 50%
    }
    
  }
  .buttonContainer {
    margin: 1.8rem 0;
  }
}
