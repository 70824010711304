@import '../../../sass/main.scss';

.slick-slider {
  @include respond-to(mobile) {
    padding-bottom: 0px;
  }
  padding-bottom: 40px;
}
.slick-prev {
  left: -88px;
}
.slick-next {
  right: -88px;
}
.slick-dots {
  bottom: -0.6rem;

  @include respond-to(mobile) {
    bottom: -4.6rem;
  }

  li {
    width: 8px;
  }
  li button:before {
    background-color: $medium-gray;
    opacity: 1;
    border-radius: 10px;
    height: 8px;
    width: 8px;
    text-indent: -9999px;
    overflow: hidden;
  }
  li.slick-active {
    width: 16px;
  }
  li.slick-active button:before {
    background-color: $text-gray;
    border-radius: 5px;
    height: 8px;
    width: 16px;
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
  }
}
.badgeName {
  display: flex;
  padding-top: 16px;
  color: $brand-blue;
  font-size: var(--p-font-size);
}
