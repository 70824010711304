@import '../../../sass/main.scss';

.PrimaryMaskedInput {
  padding-bottom: 10px;
  position: relative;
  .InfoIcon {
    padding-left: 8px;
  }
  .ui.input > input {
    border-radius: 0;
  }
  .ui.input > input::-webkit-input-placeholder {
    color: $gray;
    opacity: 1;
    font-family: $font-family;
    font-size: $h6-font-size;
    letter-spacing: 0;
    line-height: var(--h3-line-height);
  }
  // .ui.input.error > input {
  //   border-color: $dark-pink;
  //   color: $dark-pink;
  //   box-shadow: none;
  //   font-size: 12px;
  // }
}

.PrimaryMaskedInput {
  display: flex;
  align-items: center;
  input {
    border: 1px solid $medium-gray !important;
    border-radius: 0 !important;
    font-family: $font-family !important;
    font-size: $p-font-size !important;
    padding: 0.67857143em 1em;
    &:focus {
      border-color: $dark-focus-blue !important;
    }
  }
  input:disabled {
    opacity: 0.5;
    background-color: $white;
  }
}
.PrimaryMaskedInput.error {
  padding-bottom: 4px;
  input {
    border-color: $darkRed !important;
  }
}
.PrimaryMaskedInput.error + .TopLabelError {
  font-size: $caption-mobile-font-size;
  color: $darkRed;
}
