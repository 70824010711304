@import '../../sass/main.scss';

.SecondTestimonialsSliderContainer.ui.grid {
  height: auto;
  margin-bottom: 50px !important;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  margin-top: 24px;
  padding-left: calc((100vw - 920px) / 2);
  padding-right: calc((100vw - 1090px) / 2);

  @include respond-to(tablet) {
    border-bottom-left-radius: 125px;
    border-bottom-right-radius: 125px;
    padding-left: 48px;
    padding-right: 48px;
  }
  @include respond-to(mobile) {
    margin-bottom: 100px !important;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .row > h1,
  h2,
  h3,
  h4,
  h6 {
    text-align: center;
    margin: auto;
  }
  .slick-slider {
    width: 100%;
    @include respond-to(tablet) {
      width: 100%;
    }
    @include respond-to(mobile) {
      width: 100%;
    }
    margin: 0 auto 50px auto;
    .slick-dots {
      bottom: -36px;
      li {
        width: 8px;
      }
      li button:before {
        background-color: $medium-gray;
        opacity: 1;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: $text-gray;
        border-radius: 5px;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    div.slick-list {
      padding: 0px !important;
      .slick-track {
        margin-bottom: 10px;
      }
    }
    .slick-prev {
      left: -5%;
      &:hover {
        path {
          fill: $brand-orange;
        }
      }
    }
    .slick-next {
      right: -5%;
      &:hover {
        path {
          fill: $brand-orange;
        }
      }
    }
  }
  .slick-arrow {
    top: 40%;
    height: 50px;
    width: 50px;
  }
}
