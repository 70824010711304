@import '../../../sass/main.scss';

@mixin onAction-with-color($color) {
  border-color: $color !important;
  background-color: $color !important;
  color: $white !important;
}

@mixin common-button-style {
  border-radius: 27px !important;
  color: $white;
  letter-spacing: 0 !important;
  text-align: center !important;
}

// text underline button common styles
@mixin text-underline-button-style($color) {
  // font-size: 18px !important;
  font-weight: normal !important;
  font-family: $font-family !important;
  text-decoration: underline;
  padding: 0 !important;
  background-color: transparent;
  color: $color;
}

//common arrow button styling
@mixin common-arrow-btn-style($color) {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: 'AvenirNextBold' !important;
  padding: 0 !important;
  background-color: transparent;
  color: $color;
  a {
    font-family: 'AvenirNextBold' !important;
  }
  svg {
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    path:last-child {
      fill: $color;
    }
  }
  .leftArrow {
    margin-right: 0.8rem;
  }
  .rightArrow {
    margin-left: 0.8rem;
  }
  .Windows {
    margin-bottom: 0.19rem;
  }
}

.arrow {
  display: none;
}

.ui.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1rem;
  outline: 0;
  border: none;
  vertical-align: baseline;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  -webkit-tap-highlight-color: transparent;

  &.button-loading {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    transition: all 0s linear, opacity 0.1s ease;

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -1rem 0 0 -1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(0, 0, 0, 0.15);
    }

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -1rem 0 0 -1rem;
      width: 2rem !important;
      height: 2rem;
      animation: button-spin 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 50%;
      border-color: var(--color-white) transparent transparent;
      border-style: solid;
      border-width: 0.3rem;
      box-shadow: 0 0 0 0.1rem transparent;
    }
  }
}

.SolidBlueButton,
.ui.SolidBlueButton.button,
.ui.active.SolidBlueButton,
.ui.active.button.SolidBlueButton,
.SolidOrangeButton,
.ui.SolidOrangeButton.button,
.ui.active.SolidOrangeButton,
.ui.active.button.SolidOrangeButton {
  @include common-button-style;
  border: none;
}

.SolidBlueButton:hover,
.ui.SolidBlueButton:hover .ui.active.SolidBlueButton:hover,
.ui.active.button.SolidBlueButton:hover,
.SolidBlueButton:active,
.ui.SolidBlueButton:active .ui.active.SolidBlueButton:active,
.ui.active.button.SolidBlueButton:active,
.SolidBlueButton:focus,
.ui.SolidBlueButton:focus .ui.active.SolidBlueButton:focus,
.ui.active.button.SolidBlueButton:focus {
  @include onAction-with-color($dark-solid-blue);
}

.SolidOrangeButton:hover,
.ui.SolidOrangeButton:hover .ui.active.SolidOrangeButton:hover,
.ui.active.button.SolidOrangeButton:hover,
.SolidOrangeButton:active,
.ui.SolidOrangeButton:active .ui.active.SolidOrangeButton:active,
.ui.active.button.SolidOrangeButton:active,
.SolidOrangeButton:focus,
.ui.SolidOrangeButton:focus .ui.active.SolidOrangeButton:focus,
.ui.active.button.SolidOrangeButton:focus {
  @include onAction-with-color($brand-orange-hover);
}

// .SolidBlueButton:hover {
//   background-color: $brand-blue !important;
// }

.SolidBlue.ui {
  background-color: $dark-focus-blue;
}

.SolidOrange.ui {
  background-color: $brand-orange;
}

// Outline Button Style START

.OutlineBlueButton,
.ui.OutlineBlueButton.button,
.ui.active.OutlineBlueButton,
.ui.active.button.OutlineBlueButton {
  @include common-button-style;
  color: $dark-focus-blue;
  border: 1px solid $dark-focus-blue;
  background-color: $white; // giving white grey as it is overall background
}

.OutlineBlackButton,
.ui.OutlineBlackButton.button,
.ui.active.OutlineBlackButton,
.ui.active.button.OutlineBlackButton {
  @include common-button-style;
  color: $rich-black;
  border: 1px solid $rich-black;
  background-color: transparent; // adding transparent background, so that it takes the parent background
}

.OutlineOrangeButton,
.ui.OutlineOrangeButton.button,
.ui.active.OutlineOrangeButton,
.ui.active.button.OutlineOrangeButton {
  @include common-button-style;
  color: $brand-orange;
  border: 1px solid $brand-orange;
  background-color: $white; // giving white grey as it is overall background
}

.OutlineBlueButton:hover,
.ui.OutlineBlueButton:hover .ui.active.OutlineBlueButton:hover,
.ui.active.button.OutlineBlueButton:hover,
.OutlineBlueButton:active,
.ui.OutlineBlueButton:active .ui.active.OutlineBlueButton:active,
.ui.active.button.OutlineBlueButton:active,
.OutlineBlueButton:focus,
.ui.OutlineBlueButton:focus .ui.active.OutlineBlueButton:focus,
.ui.active.button.OutlineBlueButton:focus {
  @include onAction-with-color($dark-solid-blue);
}

.OutlineBlackButton:hover,
.ui.OutlineBlackButton:hover .ui.active.OutlineBlackButton:hover,
.ui.active.button.OutlineBlackButton:hover,
.OutlineBlackButton:active,
.ui.OutlineBlackButton:active .ui.active.OutlineBlackButton:active,
.ui.active.button.OutlineBlackButton:active,
.OutlineBlackButton:focus,
.ui.OutlineBlackButton:focus .ui.active.OutlineBlackButton:focus,
.ui.active.button.OutlineBlackButton:focus {
  @include onAction-with-color($rich-black);
}

.OutlineOrangeButton:hover,
.ui.OutlineOrange:hover,
.ui.OutlineOrangeButton:hover .ui.active.OutlineOrangeButton:hover,
.ui.active.button.OutlineOrangeButton:hover,
.OutlineOrangeButton:active,
.ui.OutlineOrangeButton:active .ui.active.OutlineOrangeButton:active,
.ui.active.button.OutlineOrangeButton:active,
.OutlineOrangeButton:focus,
.ui.OutlineOrangeButton:focus .ui.active.OutlineOrangeButton:focus,
.ui.active.button.OutlineOrangeButton:focus {
  @include onAction-with-color($orange);
  background-color: $brand-orange-hover !important;
}

// Outline Button Style ENDS

// White Outline Orange Background Button Style START
.WhiteOutlineOrangeButton:hover,
.ui.OutlineOrange:hover,
.ui.WhiteOutlineOrangeButton:hover .ui.active.WhiteOutlineOrangeButton:hover,
.ui.active.button.WhiteOutlineOrangeButton:hover,
.WhiteOutlineOrangeButton:active,
.ui.WhiteOutlineOrangeButton:active .ui.active.WhiteOutlineOrangeButton:active,
.ui.active.button.WhiteOutlineOrangeButton:active,
.WhiteOutlineOrangeButton:focus,
.ui.WhiteOutlineOrangeButton:focus .ui.active.WhiteOutlineOrangeButton:focus,
.ui.active.button.WhiteOutlineOrangeButton:focus {
  background-color: $brand-orange-hover !important;
  text-decoration: none !important;
}

.WhiteOutlineOrangeButton,
.ui.WhiteOutlineOrangeButton.button,
.ui.active.WhiteOutlineOrangeButton,
.ui.active.button.WhiteOutlineOrangeButton {
  @include common-button-style;
  text-decoration: none;
  border: 1px solid #fff;
  background-color: $brand-orange;
}

.WhiteOutlineOrangeButton.ui {
  background-color: $brand-orange;
}

// White Outline Orange Background Button Style END
.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  opacity: 0.5 !important;
}
.Inactive {
  background-color: $disable-gray !important;
}

// text blue underline button
.BlueUnderlineButton,
.ui.BlueUnderlineButton.button,
.ui.active.BlueUnderlineButton,
.ui.active.button.BlueUnderlineButton {
  @include text-underline-button-style($dark-focus-blue);
}

// text black underline button
.BlackUnderlineButton,
.ui.BlackUnderlineButton.button,
.ui.active.BlackUnderlineButton,
.ui.active.button.BlackUnderlineButton {
  @include text-underline-button-style($BlackLightShade);
}

//arrow button stylings

//blue arrow left/right
.BlueArrowTextButton,
.ui.BlueArrowTextButton.button,
.ui.active.BlueArrowTextButton,
.ui.active.button.BlueArrowTextButton {
  @include common-arrow-btn-style($dark-focus-blue);
}
//blue arrow hover/Tap left/right
.BlueArrowTextButton:hover,
.ui.BlueArrowTextButton:hover .ui.active.BlueArrowTextButton:hover,
.ui.active.button.BlueArrowTextButton:hover,
.BlueArrowTextButton:active,
.ui.BlueArrowTextButton:active .ui.active.BlueArrowTextButton:active,
.ui.active.button.BlueArrowTextButton:active,
.BlueArrowTextButton:focus,
.ui.BlueArrowTextButton:focus .ui.active.BlueArrowTextButton:focus,
.ui.active.button.BlueArrowTextButton:focus {
  @include common-arrow-btn-style($dark-solid-blue !important);
}

//orange arrow left/right
.OrangeArrowTextButton,
.ui.OrangeArrowTextButton.button,
.ui.active.OrangeArrowTextButton,
.ui.active.button.OrangeArrowTextButton {
  @include common-arrow-btn-style($brand-orange);
}
//orange arrow hover/Tap left/right
.OrangeArrowTextButton:hover,
.ui.OrangeArrowTextButton:hover .ui.active.OrangeArrowTextButton:hover,
.ui.active.button.OrangeArrowTextButton:hover,
.OrangeArrowTextButton:active,
.ui.OrangeArrowTextButton:active .ui.active.OrangeArrowTextButton:active,
.ui.active.button.OrangeArrowTextButton:active,
.OrangeArrowTextButton:focus,
.ui.OrangeArrowTextButton:focus .ui.active.OrangeArrowTextButton:focus,
.ui.active.button.OrangeArrowTextButton:focus {
  @include common-arrow-btn-style($brand-orange-hover !important);
}

@keyframes button-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
