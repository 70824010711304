@import '../../../sass/main.scss';
.CheckboxContainer {
  .ErrorText {
    padding-left: 0.5rem;
    font-size: $caption-mobile-font-size;
    color: $darkRed;
  }
  .custom-checkbox {
    position: relative;
    padding-left: 0.5em;
    margin-bottom: 15px;
    display: flex;
    .InfoIcon {
      padding-left: 8px;
      align-self: baseline;
    }
  }
  .custom-checkbox input {
    display: none;
  }
  .custom-checkbox label {
    position: relative;
    pointer-events: none;
    padding-left: 35px;
  }
  .custom-checkbox label:before {
    content: '';
    position: absolute;
    cursor: pointer;
    pointer-events: initial;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $medium-gray;
    outline: 1px solid $white;
    background-color: $white;
  }
  .custom-checkbox input:checked + label:before {
    background-color: $text-gray;
    border: 1px solid $text-gray;
  }
  .custom-checkbox {
    label + span {
      margin-left: 8px;
      font-size: $p-font-size;
      a {
        color: $text-gray;
        text-decoration: underline;
      }
    }
  }
  .custom-checkbox input:hover + label:before {
    border: 4px solid $white;
    outline: 1px solid $medium-gray;
    background-color: $text-gray;
  }
  .custom-checkbox input:checked + label:before {
    border: 2px solid $text-gray;
    outline: 1px solid $white;
  }
  /* Checkmark (hidden when not checked)*/
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .custom-checkbox input:checked ~ label .checkmark:after {
    display: block;
  }
  /* Checkmark Style */
  .custom-checkbox .checkmark:after {
    left: 6px;
    top: 8px;
    width: 20px;
    height: 10px;
    border: solid $white;
    border-width: 0 0 3px 3px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
.CheckboxContainer.HasError {
  .custom-checkbox label:before {
    border: 1px solid $darkRed;
  }
}
