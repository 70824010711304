@import '../../sass/main.scss';

.main-container {
  .text-component-title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $h4-font-size;
      font-weight: bold;
    }
    @include respond-to(mobile) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: $p-font-size;
        font-weight: bold;
      }
    }
  }
  .text-component-description {
    width: 100%;
    padding: 40px 0;
    a {
      color: $black;
      text-decoration: underline;
    }
    ul {
      font-size: $p-font-size;
      padding: 0;
      margin: 0;
      margin-left: 17px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      li {
        padding-bottom: $h5-font-size;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    table {
      tr {
        td {
          padding: 1rem 3rem 1rem 0;
        }
      }
    }
  }
  width: 100%;
  height: 100%;
  position: relative;
}
.inline-orange {
  color: $brand-orange;
  // text-decoration: underline; //removed, needed to be handled from drupal only
}
.inline-orange-no-underline {
  color: $brand-orange;
}
@include respond-to(mobile) {
  .main-container {
    .text-component-title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: var(--p-font-size);
        font-weight: bold;
      }
    }
  }
}

#see-also-with-cta {
  .text-component-description {
    padding-bottom: 1.6rem !important;
  }
}
