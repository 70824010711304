@import '../../../sass/main.scss';

.stepper {
  display: flex;
  width: 70%;
  padding-left: 10px;
  @include respond-to(mobile) {
    width: 100%;
    padding-left: 0;
  }

  .steps {
    display: flex;
    &:first-child .step {
      @include respond-to(desktop) {
        max-width: unset;
        width: 110px;
        .title {
          width: 150px;
        }
      }
    }
    .step {
      max-width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .title {
      text-align: center;
      color: $medium-gray;
      max-width: 224px;
      width: max-content;
      h5 {
        font-family: $font-family-bold !important;
      }
      @include respond-to(tablet) {
        h5 {
          font-size: var(--p-font-size) !important;
        }
        max-width: 120px;
      }
      @include respond-to(mobile) {
        h5 {
          font-size: var(--caption-mobile-font-size) !important;
          line-height: var(--caption-mobile-line-height) !important;
        }
        max-width: 90px;
      }
      &.activeTitle {
        color: $text-gray;
      }
    }

    .completeIcon {
      background-color: $success-green;
      border: 3px solid $success-green !important;
      .checkmark {
        display: inline-block;
        transform: rotate(45deg);
        height: 33px;
        width: 15px;
        margin-left: 35%;
        margin-top: 7px;
        border: none;
        border-bottom: 6px solid $white;
        border-right: 6px solid $white;
        background: $success-green;
        position: relative;
      }
    }
    .iconImg {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 3px solid $medium-gray;
      display: flex;
      margin-bottom: 0;
      &.activeIcon {
        border: 3px solid $brand-orange;
      }
      .numText {
        font-size: var(--h5-font-size);
        margin: auto;
        font-family: $font-family-bold !important;
        color: $medium-gray;
        height: 100%;
        align-content: center;
      }
      .numTextWindows {
        font-size: var(--h5-font-size);
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        font-family: $font-family-bold !important;
        color: $medium-gray;
      }
      .activeStep {
        color: $brand-orange;
      }
    }
  }

  .dots {
    margin-top: 25px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    &.dot2 {
      display: none;
    }
    @include respond-to(desktop) {
      &.dot0 {
        margin-left: -15px;
      }
    }
    .grey-dot {
      width: 5px;
      height: 5px;
      background-color: $medium-gray;
      border-radius: 50%;
      display: inline-block;
      @include respond-to(mobile) {
        margin: 0 3px;
        &:nth-of-type(n + 4) {
          display: none;
        }
      }
      @include respond-to(tablet) {
        &:nth-of-type(n + 9) {
          display: none;
        }
      }
    }
  }
}
