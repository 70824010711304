@import '../../sass/main.scss';

.upcoming-appointments {
  &__cards-container {
    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: 220px;
          @include respond-to(tablet) {
            margin-left: 0px;
          }
          @media (max-width: 850px) {
            margin-left: 250px;
          }
          @include respond-to(mobile) {
            margin-left: 0px;
          }
        }
      }
      .slick-dots {
        bottom: -50px;
        li {
          width: 8px;
        }
        li button:before {
          background-color: $medium-gray;
          opacity: 1;
          border-radius: 10px;
          height: 8px;
          width: 8px;
          text-indent: -9999px;
          overflow: hidden;
        }
        li.slick-active {
          width: 16px;
        }
        li.slick-active button:before {
          background-color: $text-gray;
          border-radius: 5px;
          height: 8px;
          width: 16px;
          margin-left: 0;
          margin-right: 0;
        }
      }
      .slick-prev {
        width: 48px;
        height: 48px;
        left: -72px;
        &:hover {
          path {
            fill: $brand-orange;
          }
        }
      }
      .slick-next {
        width: 48px;
        height: 48px;
        right: -72px;
        &:hover {
          path {
            fill: $brand-orange;
          }
        }
      }
    }
  }
  &__cards-container.LessDataWrapper {
    .slick-slider {
      margin-bottom: 0;
      .slick-track {
        margin-left: 0;
      }
      .slick-list {
        .slick-track {
          @include respond-to(desktop) {
            margin-left: 0;
            margin-right: 0;
          }
          .slick-slide.slick-cloned {
            display: none;
          }
        }
      }
    }
  }
  .link-style {
    margin-top: 0;
    @include respond-to(mobile) {
      margin-top: 3.2rem;
    }
  }
}
