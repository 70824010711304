@import '../../../../sass/main.scss';

.TopLabel {
  font-size: $h5-font-size;
  margin-bottom: 5px;
  &.disabled {
    color: var(--color-dark-grey);
  }
}

.HtmlLabelAsterisk {
  color: $gradient-start-color;
  font-size: $p-font-size;
  margin-left: 4px;
}

.SelectHasError {
  .PrimarySelect {
    padding-bottom: 4px;
    .ui.selection.dropdown.Base {
      border-color: $darkRed;
    }
  }
  .TopLabelError .ErrorMsgContainer {
    .ErrorMsg {
      font-size: $caption-mobile-font-size;
      color: $darkRed;
    }
  }
}

.fieldLabelClass {
  color: #333333;
  font-family: 'AvenirNextBold' !important;
  font-size: var(--caption-font-size);
}
