@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.slider-carousel.slick-slider {
  width: 100%;
  height: 340px;
  img {
    object-fit: cover;
    height: 340px;
  }
  @include respond-to(mobile) {
    width: 100%;
    height: 340px;
    img {
      height: 340px;
      object-fit: cover;
    }
  }
  .slick-dots {
    bottom: 5%;
    li {
      width: 8px;
    }
    li button:before {
      background-color: white !important;
      opacity: 0.5;
      border-radius: 10px;
      height: 8px;
      width: 8px;
      text-indent: -9999px;
      overflow: hidden;
    }
    li.slick-active {
      width: 16px;
    }
    li.slick-active button:before {
      background-color: $white !important;
      border-radius: 5px;
      opacity: 1;
      height: 8px;
      width: 16px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .slick-prev {
    left: 5%;
    z-index: 99;
  }
  .slick-next {
    right: 5%;
    z-index: 99;
  }
}
.slick-arrow {
  height: 50px;
  width: 50px;
}
