@import '../../sass/main.scss';

.CenterListContainer {
  padding: 25px 0;
  width: 1008px;
  margin: 0 auto;
  .map-marker {
    border-radius: 50%;
    background-color: $brand-blue;
    height: 24px;
    width: 24px;
    border: 1px solid white;
  }
  @include respond-to(mobile) {
    max-width: 90%;
  }
  @include respond-to(tablet) {
    max-width: 90%;
  }
  h5 {
    font-family: 'AvenirNextBold' !important;
  }
  .StateDescription {
    h3 {
      font-family: 'AvenirNextBold' !important;
    }
    div {
      padding: 25px 0;
    }
  }
  .CenterListViewTypeTabs {
    .ui.secondary.pointing.menu {
      margin-bottom: 3.8rem;
    }
    .item {
      font-size: 1.8rem;
      width: 50%;
      padding: 1rem !important;
      justify-content: center;
      border-bottom: 0.4rem solid var(--color-light-shaded-grey) !important;
    }
    .active.item {
      color: $brand-orange !important ;
      border-image: linear-gradient(90deg, var(--color-dark-shaded-orange) 0%, var(--color-light-shaded-orange) 100%) 30 !important;
      border-bottom: 0.4rem solid !important;
      font-family: var(--font-family-bold) !important;
    }
    .CenterListViewContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
      @include respond-to(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond-to(mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
      .ListViewCenterCardContainer {
        margin: auto;
      }
    }
  }
  .segment.tab {
    box-shadow: none !important;
    border: none !important;
    .show-more-button {
      text-align: center;
      margin: 25px;
    }
  }
  .centerInfoCardContainer {
    position: absolute;
    transform: translate(-50%, -30%);
  }
}
