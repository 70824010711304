@import '../../sass/main.scss';

.TestimonialsSliderContainer.ui.grid {
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  margin-top: 24px;
  margin-bottom: 25rem !important;
  padding-left: calc((100vw - 1008px) / 2);
  padding-right: calc((100vw - 1008px) / 2);

  @include respond-to(tablet) {
    border-bottom-left-radius: 125px;
    border-bottom-right-radius: 125px;
    padding-left: 48px;
    padding-right: 48px;
    margin-bottom: 37rem !important;
  }
  @include respond-to(mobile) {
    margin-bottom: 20rem !important;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .TitleRow {
    margin-top: 24px;
    margin-bottom: 12px;
    max-width: 836px;
    @include respond-to(tablet) {
      max-width: 100%;
    }
  }
  .testimonials-slider-section {
    height: 27rem;
    @include respond-to(tablet) {
      height: 15rem;
    }
    @include respond-to(mobile) {
      height: 25rem;
    }
  }
  .row > h1,
  h2,
  h3,
  h4,
  h6 {
    text-align: center;
    margin: auto;
  }
  .slick-slider {
    width: 100%;
    position: absolute;
    @include respond-to(tablet) {
      width: 100%;
    }
    @include respond-to(mobile) {
      width: 100%;
    }
    margin: 0 auto 50px auto;
    .slick-dots {
      bottom: -36px;
      li {
        width: 8px;
      }
      li button:before {
        background-color: $medium-gray;
        opacity: 1;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: $text-gray;
        border-radius: 5px;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    div.slick-list {
      padding: 0 2.5%;
      @media only screen and (min-width: 1515px) {
        padding: 0 2.5% !important;
      }
      @include respond-to(tablet) {
        padding: 0 3.5% !important;
      }
      @include respond-to(mobile) {
        padding: 0 calc((100vw - 338px) / 2) !important;
      }
      .slick-track {
        margin-bottom: 10px;
      }
    }
    .slick-prev {
      left: -5%;
      &:hover {
        path {
          fill: $brand-orange;
        }
      }
    }
    .slick-next {
      right: -5%;
      &:hover {
        path {
          fill: $brand-orange;
        }
      }
    }
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
  }
}
