@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.announcement-banner-container {
  background-color: $extreme-light-blue;
  padding-bottom: 50px;
  .announcement-banner-slider.slick-slider {
    padding-bottom: 65px;
    svg {
      &:hover {
        fill: $brand-orange;
      }
    }
    width: 60%;
    margin: auto;
    @include respond-to(tablet) {
      width: 90%;
    }
    @include respond-to(mobile) {
      width: 90%;
    }
    .card {
      &:first-child {
        margin-left: 5px;
      }
      border-radius: 25px;
      padding: 15px;
      margin: 30px;
      @include respond-to(mobile) {
        margin: 30px auto;
      }
      .header {
        padding: 5px 0;
        font-size: var(p-font-size);
        font-size: 18px;
      }
      .description {
        margin: 0 !important;
        padding: 11px 0;
        padding-bottom: 32px;
      }
    }
    .slick-dots {
      li {
        width: 8px;
      }
      li button:before {
        background-color: black !important;
        opacity: 0.5;
        border-radius: 10px;
        height: 8px;
        width: 8px;
        text-indent: -9999px;
        overflow: hidden;
      }
      li.slick-active {
        width: 16px;
      }
      li.slick-active button:before {
        background-color: black !important;
        border-radius: 5px;
        opacity: 1;
        height: 8px;
        width: 16px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      left: -5%;
      z-index: 99;
    }
    .slick-next {
      right: -5%;
      z-index: 99;
    }
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
  }
}

.announcement-banner-container.LessDataWrapper {
  .announcement-banner-slider {
    width: 100%;
    max-width: 1008px;
  }
  .slick-slider {
    .slick-track {
      margin-left: 0;
    }
    .slick-slide.slick-cloned {
      display: none;
      @include respond-to(mobile) {
        display: block;
      }
    }
  }
}
