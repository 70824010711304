@import '../../../sass/main.scss';

.DesktopHeaderContainer {
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  box-shadow: none !important;

  .segments {
    margin: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .ui.menu {
    border: 0;
    border-radius: 0 !important;
    margin: 0;
    box-shadow: none;
    padding: 0 25px;
    .SearchIconContainer {
      margin: auto;
      color: white;
      padding: 0 10px;
      cursor: pointer;
    }
    .item {
      color: white;
      padding: 0 15px;
      font-weight: 800;
      border-radius: 0 !important;
      &:hover {
        color: white;
        text-decoration: underline;
        background-color: transparent !important;
      }
      &::before,
      ::after {
        width: 0;
      }

      &:last-child {
        padding-right: 0;
      }
      &.translation-text {
        text-decoration: none;
      }
    }
    .active {
      background-color: transparent !important;
      text-decoration: underline;
    }
  }
  .NavbarMenuItem {
    font-family: 'AvenirNextBold' !important;
  }
}
