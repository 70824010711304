@import '../../../sass/main.scss';

.hero-template6 {
  &__image-container {
    position: relative;
    top: -13.7rem;
    right: -18rem;
    @include respond-to(mobile-tablet) {
      top: 0;
      right: 0;
    }
    img {
      width: 56rem;
      height: 50rem;
      object-fit: contain;
      @include respond-to(mobile) {
        width: 100%;
        height: 100%;
      }
    }
  }
}
