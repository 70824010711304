@import '../../sass/main.scss';

.ContactUsConfirmationContainer {
  .text-component-description {
    margin: 0 !important;
  }

  .OutlineOrangeButton,
  .ui.OutlineOrangeButton.button {
    background-color: $white;
    margin-top: 0.5rem !important;

    @include respond-to(tablet) {
      width: fit-content;
    }
  }
}
