@import '../../sass/abstract/variables';
@import '../../sass/abstract/mixins';

.center-name-with-map-container {
  display: flex;
  flex-direction: row;
  width: 1008px;
  margin: auto !important;
  min-height: 356px;

  @include respond-to(tablet) {
    width: 90%;
  }

  @include respond-to(mobile) {
    width: 90%;
    flex-direction: column;
  }

  .center-details {
    width: 50%;

    @include respond-to(mobile) {
      width: 100%;
      margin-bottom: 1.6rem;
    }

    .center-name {
      color: $brand-blue;
    }

    .center-address {
      padding-top: 16px;
      display: flex;
      align-items: baseline;
      gap: 0.8rem;

      span,
      p {
        font-size: $p-font-size;
      }
    }

    .center-phone {
      margin: 18px 0 32px 0;
      display: flex;
      align-items: baseline;
      gap: 0.8rem;

      span,
      a {
        color: unset;
        font-size: $p-font-size;
      }
    }

    .is-spanish-center {
      margin-left: 2.5rem;
      border-radius: 0.5rem;
      background-color: $extreme-light-blue;
      width: max-content;
      padding: 4px 8px;
      color: var(--color-bl-blue) !important;
      font-size: var(--caption-font-size) !important;
      font-family: var(--font-family-medium) !important;
    }
  }

  .center-hours-container {
    width: 50%;
    height: fit-content;
    .todayDayElement {
      font-family: 'AvenirNext-Bold';
    }
    .todayHoursElement {
      font-family: 'AvenirNext-Bold';
    }
    @include respond-to(mobile) {
      width: 90%;
      margin: auto;
      tr {
        box-shadow: none !important;
      }
      td {
        width: 50%;
      }
    }

    border: none !important;

    td {
      border: none !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .center-map-container {
    div {
      border-radius: 25px;
    }

    width: 50%;

    @include respond-to(mobile) {
      margin: 25px 0;
      height: 270px;
      width: 100%;
    }
  }

  .center-get-direction {
    padding-left: 2.2rem;
    padding-top: 0.8rem;
  }
}
