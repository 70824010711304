@import '../../sass/main.scss';

.AccordionContainer {
  border-top: 1px solid $medium-gray;
  padding: 16px;

  .ui.accordion .title,
  .accordionTitle {
    display: flex;
    align-items: center;
    gap: 28px;
    justify-content: space-between;
    padding: 0;

    .chevron-arrow {
      margin-top: 4.5px;
      margin-bottom: 4.5px;
      width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:hover {
      color: $brand-orange-hover !important;

      .chevron-arrow {
        svg {
          & > :first-child {
            fill: $brand-orange-hover;
          }
        }
      }
    }

    @include respond-to(mobile) {
      align-items: flex-start;
      h5 {
        font-size: var(--h5-font-size) !important;
        line-height: var(--h5-line-height) !important;
      }
    }
  }

  .ui.accordion .content {
    padding: 32px 29px 24px 0 !important;

    .AccordionContentContainer {
      font-size: var(--p-font-size);
      line-height: var(--p-line-height);

      & > :not(:first-child) {
        padding-top: 24px;
      }

      ul,
      ol {
        padding: 24px 0 0 0;
        list-style-position: outside;
        margin-left: 1.6rem;

        & > :not(:first-child) {
          padding-top: 16px;
        }
      }
    }

    .AccordionButton {
      margin-top: 32px;
    }

    .BlueArrowTextButton {
      line-height: 1 !important;
    }
  }

  .accordionTitle {
    color: $brand-orange;
  }

  .AccordionContainer {
    border-top: 1px solid $medium-gray;
  }
}

.appointment-history-container {
  .AccordionContainer {
    .ui.accordion {
      .content {
        padding: 0rem 0rem 0rem 0 !important;

        .AccordionDetails {
          padding-top: 3.2rem;
        }
      }
    }
  }
}

.debit-history-container,
.payment-history-container {
  .main-container {
    padding-left: 0 !important;
  }

  .AccordionContainer {
    .ui.accordion .content {
      padding: 32px 0 0 0 !important;
    }
  }
}

.rewards-history-container,
.debit-history-container,
.payment-history-container,
.appointment-history-container {
  @include respond-to(mobile) {
    .main-container.accordion-component {
      padding: 0 !important;
    }
  }

  @include respond-to(tablet) {
    .main-container.accordion-component {
      padding: 0 !important;
    }
  }
}
