@import '../../sass/main.scss';

.ContactUsContainer {

  .text-component-description {
    margin: 0 !important;
  }

  .ContactUsForm {
    .ui.form .field {
      margin-bottom: 2rem;
    }

    .PrimarySelect .ui.selection.dropdown.Base {
      i.icon {
        font-size: 1.8rem;
        margin-bottom: 0.4rem;
      }

      .menu .item, .divider.text {
        text-transform: capitalize;
      }

      &.Subject .menu .item{
        text-transform: none;
      }
    }

    .SolidBlueButton, .ui.SolidBlueButton.button  {
      margin-top: 2.2rem;

      @include respond-to(mobile) {
        margin-top: 4rem;
      }
    }
  }

  .three {
    .OutlineOrangeButton {
      background-color: $white;
      margin-top: 0.5rem !important;

      @include respond-to(tablet) {
        width: fit-content;
      }
    }
  }

  .ui.grid>.column.row>[class*="eight wide computer"].column, 
  .ui.grid>.row>[class*="eight wide computer"].column {
    margin-bottom: 1.8rem !important;

    @include respond-to(mobile-tablet) {
      margin-bottom: 4.8rem !important;
    }
  }

  @include respond-to(tablet) {
    .main-container {
      .ui.grid>.column.row>[class*="eight wide computer"].column, 
      .ui.grid>.row>[class*="eight wide computer"].column {
        padding-right: 2.5rem;
        width: 50% !important;
      }

      .AccordionContainer .ui.accordion .title {
        align-items: flex-start;
      }

      .three {
        .manageGridColumnPadding {
            margin-bottom: 0 !important;

          .headingText {
            h4 {
              font-size: 2.4rem !important;
              line-height: 3rem !important;
            }
          }
        }
        .paragraph {
          min-height: 12rem !important;
        }
      }
    }

    .separator-wrapper {
      hr {
        width: 100% !important;
      }
    }
  }

  @include respond-to(mobile) {
    .AccordionContainer .ui.accordion .title {
      h5 {
        font-size: 2.4rem !important;
      }
    }
    .manageGridColumnPadding {
      margin-bottom: 4.8rem !important;

      .headingText {
        h5 {
          font-size: 2.4rem !important;
        }
      }
    }
    .manageGridColumnPadding:last-child {
      margin-bottom: 0rem !important;
    }
    .paragraph {
      min-height: 0!important;
    }
    .separator-wrapper {
      hr {
        width: 100% !important;
      }
    }
    .ui.button {
      width: 100%;
    }
    .separator-wrapper {
      .Separator {
        width: 100% !important;
      }
    }
  }
}
