@import '../../sass/main.scss';

.SignUpSuccessContainer {
  .main-container {
    .form-confirmation-title {
      padding-top: 10px;
      width: 70%;
    }
    .form-confirmation-description {
      padding-top: 24px;
    }

    @include respond-to(tablet) {
      .form-confirmation-title {
        width: 100%;
      }
    }

    @include respond-to(mobile) {
      .form-confirmation-title{
        width: 100%;
      }
    }
  }
}
