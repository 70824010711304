@import '../../sass/main.scss';

.CenterStatesListContainer {
  width: 1008px !important;

  @include respond-to(mobile-tablet) {
    width: auto !important;
  }

  .CenterStatesList {
    padding: 50px 0;
    height: 690px;
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    @include respond-to(tablet) {
      height: 690px;
    }
    @include respond-to(mobile) {
      height: 1270px;
    }

    p {
      text-decoration: underline;
      margin: 0 0 18px 0 !important;
      text-decoration-color: $brand-blue;
      padding-right: 20px;
      width: 234px;
      @include respond-to(tablet) {
        width: 160px;
      }
      @include respond-to(mobile) {
        width: 160px;
      }
      a {
        text-decoration: none;
        font-family: 'AvenirNextBold' !important;
        color: $brand-blue !important;
      }
    }
  }
}
