@import '../../sass/main.scss';

.paddedRegion {
  padding-bottom: 40px;
}
.Breadcrumbs {
  padding-top: 24px;
  display: flex !important;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  .hide {
    display: none;
  }
  .mobileViewArrow {
    display: none;
  }
  .rightArrow {
    width: 20px;
    height: 20px;
    transform: rotateY(180deg);
    margin: 0 16px;
  }
  .HomeText.lastLevel {
    span {
      color: $brand-orange;
      font-family: 'AvenirNextBold' !important;
    }
  }
  .svgHome {
    width: 16px;
    height: 17.5px;
    &:hover {
      path {
        fill: $brand-orange;
      }
    }
  }
  a.Homelink,
  .HomeText span {
    color: $rich-black;
    font-size: var(--p-font-size) !important;
    // text-transform: capitalize;
  }
  a.Homelink {
    &:hover {
      color: $brand-orange;
    }
  }
  .HomeText {
    cursor: default;
  }
}
@include respond-to(mobile-tablet) {
  .paddedRegion {
    padding-bottom: 25px;
  }
}

@include respond-to(mobile) {
  .Breadcrumbs {
    .Homelink {
      display: block !important;
      span {
        color: $brand-orange !important;
        font-family: 'AvenirNextBold' !important;
      }
    }
    .mobileViewArrow {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}
